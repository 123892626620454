import {emptySplitApi} from 'api/apiSlice';
import {handleRTKQError} from 'utils/errorHandler';

const tagsSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchTags: builder.query({
      query: (belongs_to) => `/tags/tag?belongs_to=${belongs_to}`,
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, arg) => [{type: 'Tags', id: arg}],
    }),
    fetchCategories: builder.query({
      query: (belongs_to) => `/tags/category_tree?belongs_to=${belongs_to}`,
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, arg) => [{type: 'Categories', id: arg}],
    }),
    createOrUpdateTag: builder.mutation({
      query: (tag) => ({
        url: tag.id ? `/tags/tag/${tag.id}/` : `/tags/tag/`,
        method: tag.id ? 'PUT' : 'POST',
        body: tag,
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: (result, error, arg) => [
        {type: 'Tags', id: arg.belongs_to},
        arg.belongs_to === 'CMS' ? 'CmsPages' : 'Seeds',
      ],
    }),
    deleteTag: builder.mutation({
      query: ({tag_id, belongs_to}) => ({
        url: `/tags/tag/${tag_id}/`,
        method: 'DELETE',
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: (result, error, arg) => [
        {type: 'Tags', id: arg.belongs_to},
        arg.belongs_to === 'CMS' ? 'CmsPages' : 'Seeds',
      ],
    }),
    createOrUpdateCategory: builder.mutation({
      query: (category) => ({
        url: category.id ? `/tags/category/${category.id}/` : `/tags/category/`,
        method: category.id ? 'PUT' : 'POST',
        body: category,
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: (result, error, arg) => [
        {type: 'Categories', id: arg.belongs_to},
        arg.belongs_to === 'CMS' ? 'CmsPages' : 'Seeds',
      ],
    }),
    deleteCategory: builder.mutation({
      query: ({category_id, belongs_to}) => ({
        url: `/tags/category/${category_id}/`,
        method: 'DELETE',
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: (result, error, arg) => [
        {type: 'Categories', id: arg.belongs_to},
        arg.belongs_to === 'CMS' ? 'CmsPages' : 'Seeds',
      ],
    }),
  }),
});

export const {
  useFetchTagsQuery,
  useFetchCategoriesQuery,
  useCreateOrUpdateTagMutation,
  useDeleteTagMutation,
  useCreateOrUpdateCategoryMutation,
  useDeleteCategoryMutation,
} = tagsSlice;
