import {emptySplitApi} from 'api/apiSlice';
import {CROSS_GRADE, REVIEW_STATUS_DISPLAY} from 'utils/consts';
import {handleApiError, handleRTKQError} from 'utils/errorHandler';
import {calculateGradespan} from 'utils/helpers';

function getStudentTableData(e) {
  let review_status_display = e.pret_review_status || 'unreviewed';
  let pret_calculate_status_display =
    REVIEW_STATUS_DISPLAY[e.pret_calculate_status];
  let sm_status_display = e.sm_status || '';
  let exit_date_tz = new Date(Date.parse(e.exitdate + 'Z'));
  let exit_date_display = exit_date_tz.toLocaleDateString('en-US', {
    timeZone: 'America/New_York',
  });
  e.grade_level_display = e.grade_level === 0 ? 'K' : e.grade_level;
  e.next_year_grade_display = e.next_year_grade === 0 ? 'K' : e.next_year_grade;
  e.next_school = e.next_school || '[blank]';
  e.sm_next_year_school = e.sm_next_year_school || '[blank]';
  e = {
    ...e,
    review_status_display,
    pret_calculate_status_display,
    exit_date_display,
    sm_status_display,
  };
  return e;
}

const esoyApiSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchCourseSelection: builder.query({
      query: (siteId) => `/esoy/course_selection/${siteId}`,
      transformResponse: (response) => {
        let newCourseData =
          response.map((course) => {
            return {
              ...course,
              selected: course.is_homeroom ? true : course.selected,
            };
          }) || [];
        return newCourseData;
      },
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, arg) => [
        {type: 'CourseSelection', id: arg},
      ],
    }),
    fetchPretStats: builder.query({
      query: () => `/esoy/pret_stats`,
      transformErrorResponse: handleRTKQError,
      providesTags: ['PretStats'],
    }),
    fetchSchoolPretStudents: builder.query({
      query: (siteId) => `/esoy/pret_students?site_id=${siteId}`,
      transformResponse: (response) => {
        const tableData = response.map((e) => {
          return getStudentTableData(e);
        });

        const getCalcGradespan = () => {
          if (tableData.length === 0) {
            throw new Error('Table data is empty');
          }

          const grades = tableData.map((student) =>
            parseInt(student.grade_level)
          );
          const minGrade = Math.min(...grades);
          const maxGrade = Math.max(...grades);

          if (isNaN(minGrade) || isNaN(maxGrade)) {
            throw new Error('Invalid grade levels');
          }

          return Array(maxGrade - minGrade + 1)
            .fill()
            .map((_, idx) => minGrade + idx);
        };

        return {
          tableData,
          length: response.length,
          gradespan: getCalcGradespan(),
        };
      },
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, arg) => [
        {type: 'SchoolPretStudents', id: arg},
      ],
    }),
    fetchHomerooms: builder.query({
      query: (siteId) => `/esoy/school_homerooms/${siteId}`,
      transformResponse: (response) => {
        // We'll set up the data source by grade, and populate it with the previously
        // stored names, if they exist, if not we'll use a default.
        let grades = Object.keys(response);
        let data = grades.map((grade) => {
          if (!response[grade]) {
            response[grade] = [];
          }
          let gradeHomeroomNames = response[grade].map((e) => {
            return e['homeroom_title'];
          });
          let gradeHomeroomIds = response[grade].map((e) => {
            return e['homeroom_id'];
          });
          let gradeHomeroomNameSet = response[grade].map((e) => {
            return e['homeroom_name_set'];
          });

          return {
            key: grade,
            grade,
            homeroom_names: gradeHomeroomNames,
            gradeHomeroomNameSet,
            gradeHomeroomIds,
          };
        });
        return data;
      },
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, arg) => [{type: 'Homerooms', id: arg}],
    }),
    fetchHomeroomAssignments: builder.query({
      query: ({siteId, rolloverMode}) =>
        `/esoy/school_homerooms_assignment/${siteId}?rollover_mode=${rolloverMode}`,
      transformResponse: (response) => {
        let gradespan = [...new Set(response.map((e) => e.grade))];
        let gradeHomerooms = {};
        let crossgradeHomerooms = {}; // We now have an effective gradespan, we'll loop over it to create a grade homeroom
        // object for each grade.

        gradespan.forEach((grade) => {
          let newGradeHomerooms = {}; // Loop over the homerooms in this grade.

          let homeroomsInGrade = response.filter((e) => e.grade === grade); // Let's make sure unassigned is first in the order.
          // We assume the other ids return sorted from the server.

          function cmp(a, b) {
            if (a === b) {
              return 0;
            }

            if (a === 'unassigned') {
              return -1;
            }

            if (b === 'unassigned') {
              return 1;
            }

            return parseInt(a) - parseInt(b);
          }

          let homeroomIds = homeroomsInGrade.map((e) => e.homeroom_id);
          homeroomIds = homeroomIds.sort(cmp);
          homeroomsInGrade.forEach((homeroom) => {
            newGradeHomerooms[homeroom['homeroom_id']] = homeroom;
          });

          if (grade === CROSS_GRADE) {
            // We'll remove the 'unassigned' homeroom from the cross grade homerooms, since it doesn't apply.
            delete newGradeHomerooms['unassigned'];
            crossgradeHomerooms = newGradeHomerooms;
          } else {
            gradeHomerooms[grade] = {
              homeroomOrder: homeroomIds,
              homerooms: newGradeHomerooms,
            };
          }
        });
        gradespan = gradespan.filter((e) => e !== CROSS_GRADE);

        return {
          gradespan,
          gradeHomerooms,
          crossgradeHomerooms,
        };
      },
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, arg) => [
        {type: 'HomeroomAssignments', id: arg.siteId},
      ],
    }),
    fetchSchoolGradespan: builder.query({
      query: (siteId) => `/esoy/school_grade_span/${siteId}`,
      transformResponse: (response) => {
        const {grade_from, grade_to, cross_grade} = response;
        return {
          start: grade_from,
          end: grade_to,
          crossGrade: cross_grade,
          gradespan: calculateGradespan(grade_from, grade_to, cross_grade),
        };
      },
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, arg) => [{type: 'Gradespan', id: arg}],
    }),
    fetchTaskStatus: builder.query({
      query: () => `/esoy/task_status`,
      transformResponse: (response) => {
        function cmp(a, b) {
          if (b.school === null) return -1;
          if (a.school > b.school) return 1;
          if (a.school < b.school) return -1;
          return 0;
        }
        const stats = response.sort(cmp);
        return stats;
      },
      transformErrorResponse: handleRTKQError,
      providesTags: ['TaskStatus'],
    }),
    fetchTaskStatusBySchool: builder.query({
      query: (siteId) => `/esoy/task_status/${siteId}`,
      transformResponse: (response) => {
        return response[0];
      },
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, arg) => [
        {type: 'TaskStatusBySchool', id: arg},
      ],
    }),
    fetchTaskCompletionStats: builder.query({
      query: () => `/esoy/task_completion_stats`,
      transformResponse: (response) => {
        return response;
      },
      transformErrorResponse: handleRTKQError,
      providesTags: ['TaskCompletionStats'],
    }),
    fetchTeacherAssignments: builder.query({
      query: (siteId) => `/esoy/assign_teachers/${siteId}`,
      transformResponse: (response) => {
        let sortedTeachers = response.teachers.sort((a, b) =>
          a.teacher_name.localeCompare(b.teacher_name)
        );
        let gradesData = {};
        for (let grade in response) {
          if (grade !== 'teachers') {
            let homerooms = response[grade].homerooms || [];
            let courses = response[grade].courses || [];
            let assignments = response[grade].assignments || [];

            const data = courses.map((course) => {
              // Need to get the right assignment for this course.
              const assignmentsMapped = {};
              homerooms.forEach((homeroom) => {
                const homeroomId = Object.keys(homeroom)[0];
                const courseId = Object.keys(course)[0];

                const assignment = assignments.find(
                  (e) =>
                    e.course_number === courseId &&
                    e.homeroom_id.toString() === homeroomId
                );

                if (assignment) {
                  assignmentsMapped[homeroomId] = assignment.teacher_id;
                }
              });

              return {
                key: Object.keys(course)[0],
                course: Object.values(course)[0],
                courseId: Object.keys(course)[0],
                ...assignmentsMapped,
              };
            });

            gradesData[grade] = {
              grade,
              courses: data,
              homerooms,
              assignments,
            };
          }
        }
        return {
          gradesData,
          sortedTeachers,
        };
      },
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, arg) => [
        {type: 'TeacherAssignments', id: arg},
      ],
    }),
    fetchLatestDownloadsInfo: builder.query({
      query: () => `/esoy/school_setup_export/latest_downloads`,
      transformErrorResponse: handleRTKQError,
      providesTags: ['LatestDownloadsInfo'],
    }),
    shuffleGradeHomeroomAssignments: builder.mutation({
      query: ({siteId, grade, rolloverMode}) => ({
        url: `/esoy/school_homerooms_assignment_shuffle/${siteId}/${grade}?rollover_mode=${rolloverMode}`,
        method: 'POST',
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: (result, error, arg) => {
        return [{type: 'HomeroomAssignments', id: arg.siteId}];
      },
    }),
    setGradespan: builder.mutation({
      query: ({siteId, gradespan}) => ({
        url: `/esoy/school_grade_span/${siteId}`,
        method: 'POST',
        body: gradespan,
      }),
      async onQueryStarted(arg, {dispatch, queryFulfilled}) {
        // Optimistically update the cache
        const update = dispatch(
          esoyApiSlice.util.updateQueryData(
            'fetchSchoolGradespan',
            arg.siteId,
            (draft) => {
              draft.start = arg.gradespan.grade_from;
              draft.end = arg.gradespan.grade_to;
              draft.crossGrade = arg.gradespan.cross_grade;
              draft.gradespan = calculateGradespan(
                arg.gradespan.grade_from,
                arg.gradespan.grade_to,
                arg.gradespan.cross_grade
              );
            }
          )
        );
        try {
          await queryFulfilled;
        } catch (e) {
          // Revert the cache update on error
          update.undo();
        }
      },
      transformErrorResponse: handleRTKQError,
      invalidatesTags: (result, error, arg) => {
        return [
          // {type: 'Gradespan', id: arg.siteId},
          {type: 'Homerooms', id: arg.siteId},
          {type: 'HomeroomAssignments', id: arg.siteId},
          {type: 'TeacherAssignments', id: arg.siteId},
          {type: 'CourseSelection', id: arg.siteId},
        ];
      },
    }),
    setHomerooms: builder.mutation({
      query: ({siteId, homerooms}) => ({
        url: `/esoy/school_homerooms/${siteId}`,
        method: 'POST',
        body: homerooms,
      }),
      async onQueryStarted(arg, {dispatch, queryFulfilled}) {
        // Optimistically update the cache
        const update = dispatch(
          esoyApiSlice.util.updateQueryData(
            'fetchHomerooms',
            arg.siteId,
            (draft) => {
              try {
                draft.forEach((grade) => {
                  const newGrade = arg.homerooms[grade.key];
                  if (newGrade) {
                    grade.homeroom_names = newGrade.map((e) => {
                      return e['homeroom_title'];
                    });
                    grade.gradeHomeroomIds = newGrade.map((e) => {
                      return e['homeroom_id'];
                    });
                    grade.gradeHomeroomNameSet = newGrade.map((e) => {
                      return e['homeroom_name_set'];
                    });
                  }
                });
              } catch (e) {
                console.error(e);
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch (e) {
          // Revert the cache update on error
          update.undo();
        }
      },
      transformErrorResponse: handleRTKQError,
    }),
    setHomeroomAssignments: builder.mutation({
      query: ({siteId, assignments}) => ({
        url: `/esoy/school_homerooms_assignment/${siteId}`,
        method: 'PUT',
        body: assignments,
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: (result, error, arg) => {
        return [{type: 'HomeroomAssignments', id: arg.siteId}];
      },
    }),
    cleanupHomeroomAssignments: builder.mutation({
      query: (siteId) => ({
        url: `/esoy/school_homerooms_assignment_cleanup/${siteId}?rollover_mode=pre`,
        method: 'POST',
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: (result, error, arg) => {
        return [{type: 'HomeroomAssignments', id: arg.siteId}];
      },
    }),
    setTaskStatus: builder.mutation({
      query: ({siteId, taskName, status}) => ({
        url: `/esoy/task_status/${siteId}`,
        method: 'PUT',
        body: {
          task: taskName,
          completed: status,
        },
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: (result, error, arg) => {
        return [{type: 'TaskStatusBySchool', id: arg.siteId}, 'TaskStatus'];
      },
    }),
    setTeacherAssignments: builder.mutation({
      query: ({siteId, assignment}) => ({
        url: `/esoy/assign_teachers/${siteId}`,
        method: 'PUT',
        body: assignment,
      }),
      async onQueryStarted(arg, {dispatch, queryFulfilled}) {
        // Optimistically update the cache
        const update = dispatch(
          esoyApiSlice.util.updateQueryData(
            'fetchTeacherAssignments',
            arg.siteId,
            (draft) => {
              try {
                const gradeData = draft.gradesData[arg.assignment.grade];
                const course = gradeData.courses.find(
                  (e) => e.courseId === arg.assignment.course_number
                );
                course[arg.assignment.homeroom_id] = arg.assignment.teacher_id;
              } catch (e) {
                console.error(e);
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch (e) {
          // Revert the cache update on error
          update.undo();
        }
      },
      transformErrorResponse: handleRTKQError,
    }),
    setStudentReviewStatus: builder.mutation({
      query: ({studentId, newStatus, siteId}) => ({
        url: `/esoy/pret/${studentId}`,
        method: 'PUT',
        body: {status: newStatus},
      }),
      async onQueryStarted(arg, {dispatch, queryFulfilled}) {
        // Optimistically update the cache
        const update = dispatch(
          esoyApiSlice.util.updateQueryData(
            'fetchSchoolPretStudents',
            arg.siteId,
            (draft) => {
              const student = draft.tableData.find(
                (e) => e.student_id === arg.studentId
              );
              if (student) {
                student.pret_review_status = arg.newStatus;
                student.review_status_display = arg.newStatus;
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch (e) {
          // Revert the cache update on error
          update.undo();
        }
      },
      transformErrorResponse: handleRTKQError,
      invalidatesTags: (result, error, arg) => {
        return [{type: 'HomeroomAssignments', id: arg.siteId}];
      },
    }),
    setMultipleStudentsReviewStatus: builder.mutation({
      query: ({students, siteId}) => ({
        url: `/esoy/pret`,
        method: 'POST',
        body: students,
      }),
      async onQueryStarted(arg, {dispatch, queryFulfilled}) {
        // Optimistically update the cache
        const update = dispatch(
          esoyApiSlice.util.updateQueryData(
            'fetchSchoolPretStudents',
            arg.siteId,
            (draft) => {
              for (const student of arg.students) {
                const studentData = draft.tableData.find(
                  (e) => e.student_id === student.student_id
                );
                if (studentData) {
                  studentData.pret_review_status = student.status;
                  studentData.review_status_display = student.status;
                }
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch (e) {
          // Revert the cache update on error
          update.undo();
        }
      },
      transformErrorResponse: handleRTKQError,
      invalidatesTags: (result, error, arg) => {
        return [{type: 'HomeroomAssignments', id: arg.siteId}];
      },
    }),
    setSchoolCourse: builder.mutation({
      query: ({siteId, course}) => ({
        url: `/esoy/course_selection/${siteId}`,
        method: 'PUT',
        body: course,
      }),
      async onQueryStarted(arg, {dispatch, queryFulfilled}) {
        // Optimistically update the cache
        const update = dispatch(
          esoyApiSlice.util.updateQueryData(
            'fetchCourseSelection',
            arg.siteId,
            (draft) => {
              const course = draft.find(
                (e) =>
                  e.course_number === arg.course[0].course_number &&
                  e.grade === arg.course[0].grade &&
                  e.crossgrade === arg.course[0].crossgrade
              );
              if (course) {
                course.selected = arg.course[0].selected;
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch (e) {
          // Revert the cache update on error
          update.undo();
        }
      },
      transformErrorResponse: handleRTKQError,
      invalidatesTags: (result, error, arg) => {
        return [{type: 'TeacherAssignments', id: arg.siteId}];
      },
    }),
  }),
});

export const {
  useFetchCourseSelectionQuery,
  useFetchPretStatsQuery,
  useFetchSchoolPretStudentsQuery,
  useFetchHomeroomsQuery,
  useFetchHomeroomAssignmentsQuery,
  useFetchSchoolGradespanQuery,
  useFetchTaskStatusQuery,
  useFetchTaskStatusBySchoolQuery,
  useFetchTaskCompletionStatsQuery,
  useFetchTeacherAssignmentsQuery,
  useFetchLatestDownloadsInfoQuery,
  useShuffleGradeHomeroomAssignmentsMutation,
  useSetGradespanMutation,
  useSetHomeroomsMutation,
  useSetHomeroomAssignmentsMutation,
  useCleanupHomeroomAssignmentsMutation,
  useSetTaskStatusMutation,
  useSetTeacherAssignmentsMutation,
  useSetStudentReviewStatusMutation,
  useSetMultipleStudentsReviewStatusMutation,
  useSetSchoolCourseMutation,
} = esoyApiSlice;
