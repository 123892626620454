import {Alert, Checkbox, Form, Select, Spin, message} from 'antd';
import {useFetchSchoolQuery} from 'api/apiSlice';
import {
  useFetchSchoolGradespanQuery,
  useSetGradespanMutation,
} from 'api/esoySlice';
import TaskComplete from 'components/TaskComplete';
import HomeroomNamingTable from 'components/esoy/HomeroomNamingTable';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {GRADES} from 'utils/consts';
import {getDisplayGrade} from 'utils/helpers';

const {Option} = Select;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0px;
`;

const SetupHomerooms = ({siteId}) => {
  const {data, isLoading} = useFetchSchoolGradespanQuery(siteId, {
    skip: !siteId,
  });
  const {data: schoolData} = useFetchSchoolQuery(siteId, {skip: !siteId});
  const [setGradespan, {error: saveError, success: saveSuccess}] =
    useSetGradespanMutation();

  const [allHomeroomsNamed, setAllHomeroomsNamed] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [startGradeMessage, setStartGradeMessage] = useState({
    text: null,
    type: null,
  });
  const [endGradeMessage, setEndGradeMessage] = useState({
    text: null,
    type: null,
  });
  const [startGradeOptions, setStartGradeOptions] = useState(null);
  const [endGradeOptions, setEndGradeOptions] = useState(null);
  const [localGradespan, setLocalGradespan] = useState({
    start: null,
    end: null,
    crossGrade: false,
  });

  useEffect(() => {
    if (saveError) message.error('Error saving grade span.');
    if (saveSuccess) message.success('Grade span saved.');
  }, [saveError, saveSuccess]);

  useEffect(() => {
    if (data && schoolData) setLocalGradespan(data);
  }, [data, schoolData]);

  useEffect(() => {
    setGradespanOptions();
  }, [localGradespan]);

  const setGradespanOptions = () => {
    const startGradesMatch =
      schoolData?.existingLowGrade === localGradespan?.start;
    const endGradesMatch =
      schoolData?.existingHighGrade === localGradespan?.end;

    setStartGradeMessage({
      text:
        localGradespan?.start > localGradespan?.end
          ? 'Start grade must be less than or equal to end grade.'
          : !startGradesMatch
            ? `Grade does not match data in Power School (${getDisplayGrade(schoolData?.existingLowGrade)}).`
            : null,
      type:
        localGradespan?.start > localGradespan?.end
          ? 'error'
          : !startGradesMatch
            ? 'warning'
            : null,
    });

    setEndGradeMessage({
      text: !endGradesMatch
        ? `Grade does not match data in Power School (${getDisplayGrade(schoolData?.existingHighGrade)}).`
        : null,
      type: !endGradesMatch ? 'warning' : null,
    });

    setStartGradeOptions(setGradeOptions('start'));
    setEndGradeOptions(setGradeOptions('end'));
  };

  const handleGradespanChanged = (type, newGrade) => {
    const newLocalGradespan = {...localGradespan, [type]: newGrade};
    setLocalGradespan(newLocalGradespan);

    if (
      newLocalGradespan.start !== null &&
      newLocalGradespan.end &&
      newLocalGradespan.start <= newLocalGradespan.end
    ) {
      setGradespan({
        siteId,
        gradespan: {
          grade_from: newLocalGradespan.start,
          grade_to: newLocalGradespan.end,
          cross_grade: newLocalGradespan.crossGrade,
        },
      })
        .unwrap()
        .then(() => {
          message.success('Grade span saved.');
        })
        .catch(() => {
          console.error('Error saving grade span.');
          message.error('Error saving grade span.');
        });
    }
  };

  const setGradeOptions = (type) => {
    return GRADES.map((grade, i) => (
      <Option key={i} value={i} disabled={type === 'end' && i < data?.start}>
        {grade}
      </Option>
    ));
  };

  return (
    <div>
      <Alert
        message="Setup Homerooms"
        description={
          <div>
            <ol>
              <li>Select Start & End Grade</li>
              <li>Select number of homerooms for each grade level</li>
              <ul>
                <li>There are 3 homerooms per grade level by default</li>
                <li>Schools can have up to 4 homerooms per grade level</li>
              </ul>
              <li>
                If using "cross-grade level" sections, check the "enable"
                checkbox on this page.
              </li>
              <ul>
                <li>
                  "Cross-grade level" homerooms are typically only used by
                  schools that have self-contained sections of students across
                  multiple grade levels.
                </li>
                <li>
                  Select the number of "cross-grade level" homeroom sections
                  needed.
                </li>
                <ul>
                  <li>
                    For MS, you will need separate sections depending on which
                    EdTech your students need access to:
                  </li>
                  <ul>
                    <li>
                      A section with access to Amplify Grade 5 Science and Grade
                      5/6 mCLASS ELA
                    </li>
                    <li>
                      A section with access to Amplify Grade 6 Science and Grade
                      5/6 mCLASS ELA
                    </li>
                    <li>
                      A section for students who don’t need access to Amplify
                      Science or mCLASS ELA
                    </li>
                  </ul>
                </ul>
              </ul>
              <li>Type a name for each homeroom</li>
              <ul>
                <li>
                  You will only be able to enter names for the number of
                  homerooms you choose
                </li>
                <li>
                  Names should only contain letters A-Z, apostrophe, and spaces
                </li>
              </ul>
            </ol>
            <p></p>
            <p>
              Click{' '}
              <a
                href="https://uncommonschoolssupport.zendesk.com/hc/en-us/articles/360061987051-Setup-Homerooms"
                target="_"
              >
                here
              </a>{' '}
              to view our kBase article with more details, screenshots, and
              examples.
            </p>
          </div>
        }
        type="success"
      />
      <Container>
        <h3>Setup Homerooms</h3>
        <TaskComplete
          key={siteId + completed}
          siteId={siteId}
          taskName="setup_homerooms"
          setCompletionStatus={setCompleted}
          isTaskCompletable={() => allHomeroomsNamed}
          taskIncompleteableMessage={
            <>Can only mark task as complete when all homerooms are named.</>
          }
          taskCompleteableMessage={
            <>All homerooms named, can mark task as complete.</>
          }
        />
      </Container>
      <Spin spinning={isLoading} size="large">
        <Form.Item
          label="Select start grade:"
          validateStatus={startGradeMessage.type}
          help={startGradeMessage.text}
        >
          <Select
            disabled={completed}
            value={localGradespan?.start}
            style={{width: 80}}
            onChange={(value) => handleGradespanChanged('start', value)}
          >
            {startGradeOptions}
          </Select>
        </Form.Item>
        <Form.Item
          label="Select end grade:"
          validateStatus={endGradeMessage.type}
          help={endGradeMessage.text}
        >
          <Select
            disabled={completed}
            value={localGradespan?.end}
            style={{width: 80}}
            onChange={(value) => handleGradespanChanged('end', value)}
          >
            {endGradeOptions}
          </Select>
        </Form.Item>
        <Form.Item>
          <Checkbox
            disabled={completed}
            onChange={(e) =>
              handleGradespanChanged('crossGrade', e.target.checked)
            }
            checked={data?.crossGrade}
          >
            Enable cross-grade homerooms
          </Checkbox>
        </Form.Item>
        {data?.gradespan && (
          <>
            <h3>Name Homerooms</h3>
            <HomeroomNamingTable
              key={data?.gradespan}
              gradespan={data?.gradespan}
              siteId={siteId}
              setAllHomeroomsNamed={setAllHomeroomsNamed}
              allHomeroomsNamed={allHomeroomsNamed}
              completed={completed}
            />
          </>
        )}
      </Spin>
    </div>
  );
};

export default SetupHomerooms;
