import moment from 'moment';
import {ALPHABET, CROSS_GRADE} from 'utils/consts';

// Returns the pluralized version of the grade.
export function pluralizeGrade(grade) {
  let gradeStr = grade.toString();
  let output = '';
  if (gradeStr === '0') {
    output = 'Kth';
  } else if (gradeStr === '1') {
    output = '1st';
  } else if (gradeStr === '2') {
    output = '2nd';
  } else if (gradeStr === '3') {
    output = '3rd';
  } else if (
    gradeStr === 'Cross Grade' ||
    gradeStr === CROSS_GRADE.toString()
  ) {
    output = 'Cross';
  } else {
    output = grade + 'th';
  }
  return output;
}

// Converts a timestamp to a date string in the format of MM/DD/YYYY.
export function tsToNy(ts) {
  const job_start = new Date(Date.parse(ts));
  const usaTime = job_start.toLocaleString('en-US', {
    timeZone: 'America/New_York',
  });
  return usaTime;
}

// Returns the display grade of the grade number.
export function getDisplayGrade(gradenum) {
  if (gradenum === undefined) {
    return '';
  }
  if (gradenum.toString() === CROSS_GRADE.toString()) {
    return 'Cross Grade';
  } else if (gradenum === 'K' || gradenum.toString() === '0') {
    return 'Kindergarten';
  }
  return 'Grade ' + gradenum;
}

// Returns the percentage of each number in the array.
export const getPercentages = (nums) => {
  const sum = nums.reduce((a, b) => a + b, 0);
  return nums.map((num) => ((num / sum) * 100).toFixed(2));
};

// Calculates the gradespan. If starting or ending don't exist, returns [].
// @return: ["K", "1", "2", ...] from starting to ending, and including cross grade if enabled.
export const calculateGradespan = (starting, ending, crossGradeEnabled) => {
  if (starting === null || ending === null) return [];
  if (starting > ending) return [];
  const gradespan = [];
  for (let i = starting; i <= ending; i++) {
    gradespan.push(i);
  }
  if (crossGradeEnabled) {
    gradespan.push(CROSS_GRADE);
  }
  return gradespan;
};

// Returns number if it is between min and max, otherwise returns min or max.
export function clamp(num, min, max) {
  if (isNaN(num)) return min;
  return num <= min ? min : num >= max ? max : num;
}

// Compares two objects by their length.
// @return: 1 if a is longer than b, -1 if b is longer than a, 0 if equal.
export const compareByLength = (a, b) => {
  if (a.data[a.data.length - 1].y > b.data[b.data.length - 1].y) return 1;
  if (a.data[a.data.length - 1].y < b.data[b.data.length - 1].y) return -1;
  return 0;
};

// Parses a date and returns a string with the operator and date.
// If the date is invalid, returns the operator.
// If the operator is undefined, returns the date.
// If the operator is BETWEEN, returns the date array joined by a dash.
export const parseDate = (date, operator, format = 'YYYY-MM-DD') => {
  let value = date;
  if (
    !date ||
    date === 'null' ||
    date === 'undefined' ||
    date === 'NaN' ||
    date === 'Invalid date'
  ) {
    return operator ?? '';
  }
  if (operator === 'BETWEEN') {
    const dates = value.toString().split(',');
    try {
      const parsedDates = dates
        .map((element) => {
          if (
            !element ||
            element === 'null' ||
            element === 'undefined' ||
            element === 'NaN' ||
            element === 'Invalid date'
          ) {
            return '';
          } else {
            return moment(element, format).format(format);
          }
        })
        .join(' - ');
      const returnVal = `BETWEEN ${parsedDates}`;
      return returnVal;
    } catch (e) {
      return 'BETWEEN';
    }
  } else {
    const oper = operator ?? '';
    try {
      value = moment(value, format).format(format);
    } catch (e) {
      return oper;
    }
    return `${oper} ${value}`;
  }
};

// Parses a number and returns a string with the operator and number.
// If the number is invalid, returns the operator.
// If the operator is undefined, returns the number.
// If the operator is BETWEEN, returns the number array joined by a dash.
export const parseNumber = (number, operator) => {
  if (
    (!number && number !== 0) ||
    number === 'null' ||
    number === 'undefined' ||
    number === 'NaN'
  ) {
    return operator ?? '';
  }
  let value = number;
  if (operator === 'BETWEEN') {
    const numbers = value.toString().split(',');
    try {
      const parsedNumbers = numbers
        .map((element) => {
          if (
            (!element && number !== 0) ||
            element === 'null' ||
            element === 'undefined' ||
            element === 'NaN'
          ) {
            return '';
          } else {
            return parseInt(element);
          }
        })
        .join(' - ');
      const returnVal = `BETWEEN ${parsedNumbers}`;
      return returnVal;
    } catch (e) {
      return 'BETWEEN';
    }
  } else {
    const oper = operator ?? '';
    try {
      value = parseInt(number);
    } catch (e) {
      return oper;
    }
    if (isNaN(value)) {
      return oper;
    }
    return `${oper} ${value}`;
  }
};

// Generates a unique id that is not in the existing array.
export const generateUniqueId = (existing = [], idType = 'string') => {
  // Generate a random 5 digit number.
  const id =
    idType === 'string'
      ? Math.random().toString(36).substring(2, 9)
      : Math.floor(Math.random() * 90000) + 10000;
  while (existing.includes(id)) {
    generateUniqueId(existing);
  }
  return id;
};

export const getLetterByIndex = (index) => {
  if (index < 0 || index >= ALPHABET.length) {
    return '';
  }
  return ALPHABET[index];
};

export const stripTags = (
  input,
  allowed = '<a><b><strong><i><em><p><br><ul><ol><li><span><div>'
) => {
  if (!input) return '';
  const tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
  const output = input.toString().replace(tags, ($0, $1) => {
    return allowed?.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
  });
  return output;
};

export const extractHTMLTextContent = (htmlString) => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlString;
  const children = tempDiv.childNodes;
  const strArray = [];
  for (let i = 0; i < children.length; i++) {
    strArray.push(children[i].textContent);
  }
  return strArray;
};

// Converts a string to a float, and returns the number if it is a valid number.
// Otherwise, returns an empty string.
// Empty string is left as an empty string.
export const stringToFloat = (str) => {
  if (str === '') {
    return '';
  }
  const num = parseFloat(str);
  return isNaN(num) ? '' : num;
};

// Decides on the color of the text based on the background color.
export const setContrast = (hexcolor) => {
  // if no color, or color is not a hex value, return black
  if (!hexcolor || !hexcolor.match(/^#[0-9A-F]{6,8}$/i)) {
    return '#000000d9';
  }
  const r = parseInt(hexcolor.substr(1, 2), 16);
  const g = parseInt(hexcolor.substr(3, 2), 16);
  const b = parseInt(hexcolor.substr(5, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? '#000000d9' : 'white';
};

export const setMailtoMessage = (email, subject, message) => {
  return `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`;
};
