import {CloseCircleOutlined, RightOutlined} from '@ant-design/icons';
import {Button, Tooltip} from 'antd';
import {useFetchAggregateReportsListQuery} from 'api/reportsSlice';
import RichTextEditor from 'components/dataPortal/RichTextEditor';
import InputField from 'components/genericComponents/Input';
import SelectOptions from 'components/genericComponents/SelectOptions';
import React, {useState} from 'react';
import {Draggable} from 'react-beautiful-dnd';
import styled from 'styled-components';

const widgetTypes = [
  // {value: 'chart', label: 'Superset Chart'},
  {value: 'text', label: 'Text'},
  {value: 'table', label: 'Data Report'},
  {value: 'dashboard', label: 'Superset Dashboard'},
  {value: 'google', label: 'Google File'},
  {value: 'pdf', label: 'PDF'},
];

const Container = styled.div`
  display: flex;
  gap: 10px;
  border-radius: 2px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 8px;
  &:hover {
    border-color: #aaa;
  }
`;

const WidgetForm = ({deleteWidget, setWidget, widget}) => {
  const [visible, setVisible] = useState(false);

  const {data: reports} = useFetchAggregateReportsListQuery();

  const getStyleByState = (snapshot) => {
    if (snapshot.isDragging) {
      return {
        backgroundColor: '#eee',
      };
    }
    return {};
  };

  return (
    <Draggable
      key={widget.id}
      draggableId={`widget-${widget.id}`}
      index={widget.position}
    >
      {(provided, snapshot) => {
        const style = {
          display: 'flex',
          alignItems: 'flex-start',
          ...provided.draggableProps.style,
          ...getStyleByState(snapshot),
        };
        return (
          <Container
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            isDragging={snapshot.isDragging}
            style={style}
          >
            <Tooltip title="Remove Widget">
              <CloseCircleOutlined
                onClick={(e) => {
                  e.stopPropagation();
                  deleteWidget(widget.id);
                }}
                style={{
                  margin: '8px 0',
                }}
              />
            </Tooltip>
            <SelectOptions
              selectedOption={widget.type}
              changeSelection={(val) => setWidget(val, 'type')}
              options={widgetTypes}
              allowClear={false}
              style={{width: '180px'}}
              label="Type"
              required={true}
            />
            {widget.type === 'table' && (
              <SelectOptions
                selectedOption={reports?.publishedReportsList.find(
                  (r) => r.value.toString() === widget.source
                )}
                changeSelection={(val) => setWidget(val, 'source')}
                options={reports?.publishedReportsList}
                style={{flexGrow: 1}}
                label="Data Report"
                required={true}
              />
            )}
            {widget.type === 'dashboard' && (
              <>
                <InputField
                  value={widget.dashboard_id}
                  onChange={(e) => setWidget(e.target.value, 'dashboard_id')}
                  style={{minWidth: '70px', width: '70px'}}
                  label="ID"
                  required={true}
                />
                <InputField
                  value={widget.source}
                  onChange={(e) => setWidget(e.target.value, 'source')}
                  style={{flexGrow: 1}}
                  label="Embed ID"
                  required={true}
                />
              </>
            )}
            {widget.type === 'google' && (
              <InputField
                value={widget.source}
                onChange={(e) => setWidget(e.target.value, 'source')}
                style={{flexGrow: 1}}
                label="Google File URL"
                required={true}
              />
            )}
            {widget.type === 'text' && (
              <>
                {/* truncated html */}
                <div
                  dangerouslySetInnerHTML={{__html: widget.content}}
                  style={{
                    maxHeight: '80px',
                    overflow: 'hidden',
                    zoom: 0.7,
                    flexGrow: 1,
                  }}
                />
                <Button onClick={() => setVisible(!visible)}>
                  Edit
                  <RightOutlined />
                </Button>
              </>
            )}
            {widget.type === 'pdf' && (
              <InputField
                value={widget.source}
                onChange={(e) => setWidget(e.target.value, 'source')}
                style={{flexGrow: 1}}
                label="PDF URL"
                required={true}
              />
            )}
            <RichTextEditor
              initialValue={widget.content}
              onChange={(content) => setWidget(content, 'content', false)}
              visible={visible}
              setVisible={setVisible}
            />
          </Container>
        );
      }}
    </Draggable>
  );
};

export default WidgetForm;
