import {CheckCircleTwoTone, StopOutlined} from '@ant-design/icons';
import {Button, Spin, Table} from 'antd';
import {useFetchTaskStatusQuery, useSetTaskStatusMutation} from 'api/esoySlice';
import EsoyTaskCompletionGraph from 'components/esoy/EsoyTaskCompletionGraph';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {tsToNy} from 'utils/helpers';

function EsoyTaskStats({schools}) {
  const {data: taskStats, isLoading, isSuccess} = useFetchTaskStatusQuery();
  const [setTaskStatus] = useSetTaskStatusMutation();

  const [filteredTaskStats, setFilteredTaskStats] = useState([]);

  useEffect(() => {
    if (taskStats) {
      filterStats(taskStats);
    }
  }, [taskStats, schools]);

  const filterStats = (records) => {
    const filteredRecords = records.filter((record) =>
      schools?.find((school) => school.school_number === record.site_id)
    );
    setFilteredTaskStats(filteredRecords);
  };

  const undoTaskComplete = (siteId, taskName) => {
    setTaskStatus({
      siteId,
      taskName,
      status: false,
    });
  };

  const getColumnsForTable = () => {
    const colNames = [
      {title: 'NYSS', dataIndex: 'next_year_student_status'},
      {title: 'Setup Homerooms', dataIndex: 'setup_homerooms'},
      {title: 'Assign Homerooms', dataIndex: 'assign_homerooms'},
      {title: 'Offer Courses', dataIndex: 'offer_courses'},
      {title: 'Assign Teachers', dataIndex: 'assign_teachers'},
      {title: 'Staff Roles', dataIndex: 'staff_app_roles'},
      {
        title: 'Generated/archived report cards',
        dataIndex: 'generated_archived_report_cards',
      },
      {
        title: 'Floored all grades (no stored grade <55)',
        dataIndex: 'floored_all_grades',
      },
      {
        title: 'Added exit comment/code fields for all student',
        dataIndex: 'added_exit_comment_code_fields_for_all_students',
      },
      {title: 'Print transcripts (for HS)', dataIndex: 'print_transcripts'},
    ];

    const columns = [
      {
        title: 'School',
        dataIndex: 'school',
        key: 'school',
        // link text to school page
        render: (value, row) => {
          return <Link to={`/school_setup/${value}`}>{value}</Link>;
        },
      },
    ];

    for (const colName of colNames) {
      columns.push({
        title: colName.title,
        dataIndex: [colName.dataIndex, 'completed'],
        key: colName.dataIndex,
        render: (value, row) =>
          value ? (
            <>
              <CheckCircleTwoTone />
              <div style={{fontSize: '8px'}}>
                <div>
                  Completed on: {tsToNy(row[colName.dataIndex]?.timestamp)}
                </div>
                <div>Completed by: {row[colName.dataIndex]?.username}</div>
                <Button
                  style={{
                    fontSize: '8px',
                    padding: 0,
                    border: 0,
                    height: 'auto',
                  }}
                  type="link"
                  onClick={() =>
                    undoTaskComplete(row.site_id, colName.dataIndex)
                  }
                >
                  Undo Task Complete
                </Button>
              </div>
            </>
          ) : (
            <StopOutlined />
          ),
        filters: [
          {text: 'Completed', value: true},
          {text: 'Not Completed', value: false},
        ],
        onFilter: (value, record) =>
          record[colName.dataIndex].completed === value,
      });
    }
    return columns;
  };

  return (
    <div className="esoy-it-support-stats-table">
      <Spin spinning={isLoading}>
        {isSuccess && (
          <EsoyTaskCompletionGraph
            key={filteredTaskStats}
            dataSource={filteredTaskStats}
          />
        )}
      </Spin>
      <Table
        dataSource={filteredTaskStats}
        loading={isLoading}
        columns={getColumnsForTable()}
        pagination={{
          pageSize: 100,
          hideOnSinglePage: true,
          showSizeChanger: false,
        }}
        size="small"
        rowKey="site_id"
        bordered
        scroll={{x: '100%'}}
        title={(currentPageData) => {
          return <div>Currently displayed: {currentPageData.length}</div>;
        }}
      />
    </div>
  );
}

export default EsoyTaskStats;
