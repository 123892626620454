import {
  CalendarOutlined,
  NumberOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {Button, Input, Table, Tooltip} from 'antd';
import {OperatorFilter} from 'components/genericComponents/FilterFields';
import FiltersDisplay from 'components/genericComponents/FiltersDisplay';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import Highlighter from 'react-highlight-words';
import {Link} from 'react-router-dom';

const DataTable = ({
  params,
  setParams,
  data,
  columns,
  filters,
  bordered,
  size,
}) => {
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [pagination, setPagination] = useState({});
  const searchInput = useRef();

  useEffect(() => {
    if (!columns) return;
    // set filteredInfo to object with all keys from columns, with values from params
    const newFilteredInfo = {};
    columns?.forEach((col) => {
      if (params.has(`filter_${col.alias}`)) {
        newFilteredInfo[col.alias] = params.get(`filter_${col.alias}`);
      } else {
        newFilteredInfo[col.alias] = '';
      }
    });
    setFilteredInfo(newFilteredInfo);
  }, [columns, params]);

  useEffect(() => {
    const sort = params.get('sort');
    if (sort) {
      const newSortedInfo = {};
      sort.split(',').forEach((col) => {
        const [columnKey, order] = col.split(':');
        newSortedInfo[columnKey] = order;
      });
      setSortedInfo(newSortedInfo);
    }
  }, [params]);

  useEffect(() => {
    setPagination({
      pageSize: params.get('page_size') || 50,
      current: params.get('page') || 1,
      total: data?.total,
    });
  }, [params, data?.total]);

  const setupTableData = useCallback(() => {
    return (
      data?.report?.map((row, index) => ({
        ...row,
        key: index,
        ...Object.fromEntries(
          Object.entries(row).map(([k, v]) => [k, v ?? ''])
        ),
      })) || []
    );
  }, [data]);

  const setColumnSorter = useCallback((col, i, colsLength) => {
    const compare = (a, b) => {
      if (!a[col.alias] || !b[col.alias]) return;
      if (col.column_type === 'INTEGER')
        return parseInt(a[col.alias]) - parseInt(b[col.alias]);
      if (col.column_type === 'STRING')
        return a[col.alias].toString().localeCompare(b[col.alias]);
      if (col.column_type === 'DATE')
        return new Date(a[col.alias]) - new Date(b[col.alias]);
    };
    return {
      compare,
      multiple: colsLength - i, // inverse of index, highest number gets highest priority
    };
  }, []);

  const setColumnRender = useCallback(
    (col) => {
      if (col.link_text && col.column_type === 'DIMENSION') {
        return (url, record) => {
          if (!url) return null;
          const linkText = col.link_text ? record[col.link_text] : url;
          if (url.toString().includes('http')) {
            return (
              <a href={url.toString()} target="_blank" rel="noreferrer">
                {linkText ?? url}
              </a>
            );
          } else {
            return (
              <Link to={url.toString()} target="_blank">
                {linkText ?? url}
              </Link>
            );
          }
        };
      }
      if (['STRING', 'INTEGER'].includes(col.column_type)) {
        return (text) => {
          const colFilteredInfo = Array.isArray(filteredInfo[col.alias])
            ? filteredInfo[col.alias]
            : [filteredInfo[col.alias]];
          return (
            <div>
              {colFilteredInfo.length ? (
                <Highlighter
                  highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                  searchWords={colFilteredInfo}
                  autoEscape
                  textToHighlight={text?.toString() || ''}
                />
              ) : (
                text
              )}
            </div>
          );
        };
      }
      return null;
    },
    [filteredInfo]
  );

  const getColumnSearchProps = useCallback(
    (col) => {
      const dataIndex = col.alias;
      if (col.filter_type === 'FREE_TEXT') {
        return {
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
            <div style={{padding: 8}}>
              <Input
                ref={searchInput}
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys}
                onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() => handleSearch(selectedKeys, confirm)}
                style={{width: 188, marginBottom: 8, display: 'block'}}
              />
              <div className="flex-row">
                <Button
                  onClick={() => handleReset(clearFilters, confirm)}
                  size="small"
                  style={{width: 90}}
                >
                  Reset
                </Button>
                <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{width: 90}}
                >
                  Search
                </Button>
              </div>
            </div>
          ),
          filterIcon: <SearchOutlined />,
          onFilterDropdownVisibleChange: (visible) =>
            visible && setTimeout(() => searchInput.current.select()),
        };
      }
      if (['INTEGER', 'DATE'].includes(col.filter_type)) {
        return {
          filterDropdown: ({setSelectedKeys, selectedKeys, confirm}) => (
            <OperatorFilter
              selectedKeys={selectedKeys}
              setSelectedKeys={setSelectedKeys}
              handleSearch={handleSearch}
              confirm={confirm}
              col={col}
            />
          ),
          filterIcon:
            col.filter_type === 'DATE' ? (
              <CalendarOutlined />
            ) : (
              <NumberOutlined />
            ),
        };
      }
      if (col.filter_type === 'OPTIONS') {
        return {
          filters: col.filter_options?.map((filter) => ({
            text: filter,
            value: filter,
          })),
          onFilter: (value, record) =>
            Array.isArray(record[dataIndex])
              ? record[dataIndex].includes(value)
              : record[dataIndex] === value,
        };
      }
      return null;
    },
    [filteredInfo]
  );

  const getFilterValueForCol = useCallback(
    (col) => {
      if (col.filter_type === 'OPTIONS') {
        return Array.isArray(filteredInfo[col.alias])
          ? filteredInfo[col.alias]
          : filteredInfo[col.alias]
            ? filteredInfo[col.alias].split(',')
            : [];
      } else {
        return filteredInfo[col.alias] || '';
      }
    },
    [filteredInfo]
  );

  const getColumnsForTable = useCallback(() => {
    if (!columns) return [];
    const cols = columns.map((col, i) => ({
      dataIndex: col.alias,
      filteredValue: getFilterValueForCol(col),
      fixed: col.freeze ? 'left' : null,
      key: col.alias,
      render: setColumnRender(col),
      sorter: setColumnSorter(col, i, columns.length),
      sortOrder: sortedInfo[col.alias] ?? null,
      title: col.description ? (
        <Tooltip title={col.description}>{col.alias}</Tooltip>
      ) : (
        col.alias
      ),
      width: Math.max(col.alias.length * 10 + 25, 125),
      ...getColumnSearchProps(col),
    }));
    // pull out the columns that are not frozen
    const nonFrozenCols = cols.filter((col) => !col.fixed);
    // pull out the columns that are frozen
    const frozenCols = cols.filter((col) => col.fixed);
    // return the frozen columns first, then the non-frozen columns
    return [...frozenCols, ...nonFrozenCols];
  }, [columns, filteredInfo, sortedInfo]);

  const handleTableChange = (pagination, filters, sorter, extra) => {
    const newParams = new URLSearchParams();
    newParams.append('page', pagination.current || 1);
    newParams.append('page_size', pagination.pageSize || 50);

    if (Array.isArray(sorter) && sorter.length) {
      let newSortParams = '';
      // we need to order them by priority (column.sorter.multiple value), higher is higher priority
      // this is supposed to be handled by antd, but it doesn't seem to be working
      sorter.sort(
        (a, b) => b.column.sorter.multiple - a.column.sorter.multiple
      );
      sorter.forEach((col) => {
        newSortParams += `${col.field}:${col.order},`;
      });
      newParams.append('sort', newSortParams.slice(0, -1));
    } else if (sorter.field) {
      newParams.append('sort', `${sorter.field}:${sorter.order}`);
    }

    Object.entries(filters).forEach(([key, value]) => {
      if (!value || !value.length) return;
      if (Array.isArray(value)) {
        value.forEach((val) => {
          newParams.append(`filter_${key}`, val);
        });
      } else {
        newParams.append(`filter_${key}`, value);
      }
    });

    setParams(newParams.toString());
  };

  const handleSearch = (selectedKeys, confirm) => confirm();
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
  };
  const handleClearFilters = () => setParams('');
  const handleFilterChange = (alias, value) =>
    handleTableChange(
      pagination,
      {...filteredInfo, [alias]: value},
      sortedInfo,
      {action: 'filter'}
    );

  const getFilterValues = () =>
    filters?.map((filter) => ({
      ...filter,
      value: filteredInfo[filter.alias],
    })) || [];

  return (
    <>
      <FiltersDisplay
        filters={getFilterValues()}
        handleFiltersChange={handleFilterChange}
        handleClearFilters={handleClearFilters}
        key={`FiltersDisplay_${params}${JSON.stringify(filters)}`}
      />
      <Table
        pagination={pagination}
        dataSource={setupTableData()}
        columns={getColumnsForTable()}
        key={`Table_${params}`}
        size={size || 'small'}
        bordered={bordered || true}
        onChange={handleTableChange}
        scroll={{x: '100%', y: '80vh'}}
        rowKey="key"
      />
    </>
  );
};

export default DataTable;
