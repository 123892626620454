import {ResponsiveBar} from '@nivo/bar';
import {Spin} from 'antd';
import {useFetchSchoolsQuery} from 'api/apiSlice';
import {useFetchPretStatsQuery} from 'api/esoySlice';
import React, {useEffect, useState} from 'react';
import {getPercentages} from 'utils/helpers';

const barColors = {
  Promote: 'hsl(122, 85%, 79%)',
  Transfer: 'hsl(39, 97%, 65%)',
  Retain: 'hsl(61, 83%, 82%)',
  Exit: 'hsl(0, 84%, 81%)',
  'Not Reviewed': 'hsl(0, 0%, 90%)',
};

const getColor = (bar) => barColors[bar.id];

function ESoyStatusBarGraph({data, keys}) {
  return (
    <ResponsiveBar
      data={data}
      keys={keys}
      indexBy="school"
      margin={{top: 0, right: 130, bottom: 50, left: 80}}
      padding={0.3}
      layout="horizontal"
      valueScale={{type: 'linear'}}
      indexScale={{type: 'band', round: true}}
      colors={getColor}
      height={data.length * 50 + 100}
      borderColor={{from: 'color', modifiers: [['darker', 0.6]]}}
      borderWidth={1}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        format: (value) => `${value}%`,
      }}
      axisTop={null}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
      }}
      enableLabel={false}
      legends={[
        {
          dataFrom: 'keys',
          anchor: 'top-right',
          direction: 'column',
          justify: false,
          translateX: 120,
          translateY: 20,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          symbolSize: 20,
          symbolBorderColor: '#222',
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
    />
  );
}

function EsoyITSupportNyssStats({region, gradespan}) {
  const {data: schools, isLoading: loadingSchools} = useFetchSchoolsQuery();
  const {data: schoolStats, isLoading: loadingStats} = useFetchPretStatsQuery();

  const [graphData, setGraphData] = useState([]);
  const [schoolData, setSchoolData] = useState([]);

  useEffect(() => {
    if (schoolStats && schools) {
      setupSchoolData();
    }
  }, [schoolStats, schools]);

  useEffect(() => {
    if (schoolData) {
      getDataForGraph();
    }
  }, [gradespan, region, schoolData]);

  const setupSchoolData = () => {
    const mappedSchools = [];
    for (const s of schoolStats) {
      let stats = {...s};
      const schoolObj = schools.schools.find(
        (e) => e.school_number === s.school_id
      );
      if (schoolObj) {
        stats.region = schoolObj.region.trim();
        stats.gradespan = schoolObj.gradespan;
      }
      const percentages = getPercentages([
        s.review_promote,
        s.review_retain,
        s.review_transfer,
        s.review_exit,
        s.unreviewed,
      ]);
      stats.Promote = percentages[0];
      stats.Retain = percentages[1];
      stats.Transfer = percentages[2];
      stats.Exit = percentages[3];
      stats['Not Reviewed'] = percentages[4];
      mappedSchools.push(stats);
    }
    setSchoolData(mappedSchools);
  };

  const getDataForGraph = () => {
    let data = [...schoolData];
    // Filter by region and gradespan.
    if (region !== 'All') {
      data = data.filter((e) => e.region === region);
    }
    if (gradespan !== 'All') {
      data = data.filter((e) => e.gradespan === gradespan);
    }
    setGraphData(data);
  };

  const getKeysForGraph = (grade) => {
    let keys = ['Promote', 'Retain', 'Transfer', 'Exit', 'Not Reviewed'];
    return keys;
  };

  return (
    <Spin spinning={loadingSchools || loadingStats} size="large">
      <div style={{minHeight: 200}}>
        {region === 'All' && (
          <>
            {schools?.regions?.map((r) => {
              if (r === 'All') {
                return null;
              }
              const regionData = graphData.filter((e) => e.region === r);
              return (
                <div key={r}>
                  <h2>{r}</h2>
                  {!!regionData.length && (
                    <div
                      style={{
                        height: regionData.length * 50 + 100,
                      }}
                    >
                      <ESoyStatusBarGraph
                        data={regionData}
                        keys={getKeysForGraph()}
                      />
                    </div>
                  )}
                  {!regionData.length && !(loadingSchools || loadingStats) && (
                    <p>No data matching the search parameters.</p>
                  )}
                </div>
              );
            })}
            {/* schools without region */}
            <div>
              <h2>Other</h2>
              {graphData.filter((e) => !e.region).length > 0 && (
                <div
                  style={{
                    height:
                      graphData.filter((e) => !e.region).length * 50 + 100,
                  }}
                >
                  <ESoyStatusBarGraph
                    data={graphData.filter((e) => !e.region)}
                    keys={getKeysForGraph()}
                  />
                </div>
              )}
            </div>
          </>
        )}
        {region !== 'All' && (
          <>
            <h2>{region}</h2>
            {!!graphData.length && (
              <div style={{height: graphData.length * 50 + 100}}>
                <ESoyStatusBarGraph data={graphData} keys={getKeysForGraph()} />
              </div>
            )}
            {!graphData.length && !(loadingSchools || loadingStats) && (
              <p>No data matching the search parameters.</p>
            )}
          </>
        )}
      </div>
    </Spin>
  );
}

export default EsoyITSupportNyssStats;
