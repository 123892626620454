import {DownloadOutlined, LoadingOutlined} from '@ant-design/icons';
import Auth from 'Auth';
import {Button, Switch, Table, Tooltip, message} from 'antd';
import {useFetchSchoolsQuery} from 'api/apiSlice';
import {useFetchLatestDownloadsInfoQuery} from 'api/esoySlice';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {TRACK_API_BASE_URL} from 'utils/consts';
import {tsToNy} from 'utils/helpers';

const Data = styled.div`
  font-size: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

function EsoyImportFiles({filteredSchools}) {
  const {data: schoolsData} = useFetchSchoolsQuery();
  const {data: lastDownloadsData, refetch} = useFetchLatestDownloadsInfoQuery();

  const [jointImport, setJointImport] = useState(true);
  const [schools, setSchools] = useState([]);
  const files = [
    'student_homeroom_assignments',
    'course_sections_setup',
    'course_rosters',
  ];

  useEffect(() => {
    if (!schoolsData || !lastDownloadsData) {
      return;
    }
    if (lastDownloadsData.message) {
      setSchools(schoolsData.schools);
      return;
    }
    const newSchools = schoolsData.schools.map((school) => {
      const newSchool = {...school};
      for (const file of files) {
        newSchool[file] = {};
        const lastDownload = lastDownloadsData.find(
          (e) => e.site_id === school.site_id && e.file_name === file
        );
        if (lastDownload) {
          newSchool[file].date = tsToNy(lastDownload.timestamp);
          newSchool[file].username =
            `${lastDownload.first_name} ${lastDownload.last_name}`;
        }
        if (!newSchool[file]) {
          newSchool[file] = {};
        } else {
          newSchool[file].downloading = false;
        }
      }
      return newSchool;
    });
    setSchools(newSchools);
  }, [schoolsData, lastDownloadsData]);

  const filterSchools = (records) => {
    if (!filteredSchools) {
      return records;
    }
    const filteredRecords = records.filter((record) => {
      return filteredSchools.find(
        (school) => school.school_number === record.site_id
      );
    });
    return filteredRecords;
  };

  const onChangeSchoolSelection = (selectedRowKeys) => {
    const tempSchools = [...schools];
    for (const school of tempSchools) {
      school.selected = selectedRowKeys.includes(school.site_id);
    }
    setSchools(tempSchools);
  };

  const getSelectedSchools = () => {
    return schools.filter((e) => e.selected).map((e) => e.site_id);
  };

  const onToggleJointImport = () => {
    setJointImport(!jointImport);
  };

  const setFilesToGenerate = (fileType, siteId) => {
    const selectedSchools = siteId ?? getSelectedSchools();
    if (jointImport) {
      generateFile(fileType, selectedSchools);
    } else {
      for (const id of selectedSchools) {
        generateFile(fileType, [id]);
      }
    }
  };

  const getColumnsForTable = () => {
    function renderDownloadInfo(file, value, row) {
      return (
        <Data>
          {row[file] && row[file].username ? (
            <div>
              <div>Last download:</div>
              <div>{row[file].date}</div>
              <div>By {row[file].username}</div>
            </div>
          ) : (
            <div style={{color: '#D3d3d3'}}>
              <p>No previous download</p>
            </div>
          )}
          <Button
            type="primary"
            onClick={() => generateFile(file, [row.site_id])}
            size="small"
          >
            {row[file] && row[file].downloading ? (
              <LoadingOutlined />
            ) : (
              <DownloadOutlined />
            )}
          </Button>
        </Data>
      );
    }

    const columns = [
      {
        title: 'School',
        dataIndex: 'abbreviation',
        key: 'abbreviation',
        width: 150,
      },
      ...files.map((file) => {
        return {
          title: file
            .split('_')
            .map((e) => e.charAt(0).toUpperCase() + e.slice(1))
            .join(' '),
          dataIndex: file,
          key: file,
          width: 150,
          render: (value, row) => renderDownloadInfo(file, value, row),
        };
      }),
      {
        title: 'Download All',
        dataIndex: 'download_all',
        key: 'download_all',
        width: 40,
        align: 'center',
        render: (value, row) => {
          return (
            <Tooltip title={`Download all files for ${row.abbreviation}`}>
              <Button
                type="primary"
                onClick={() => {
                  for (const file of files) {
                    generateFile(file, [row.site_id]);
                  }
                }}
                size="small"
              >
                <DownloadOutlined />
              </Button>
            </Tooltip>
          );
        },
      },
    ];

    return columns;
  };

  const massGenerateFiles = (siteId) => {
    if (!siteId && getSelectedSchools().length === 0) {
      message.error('Please select at least one school.');
      return;
    }
    for (const file of files) {
      setFilesToGenerate(file, siteId ?? getSelectedSchools());
    }
  };

  const generateFile = (fileType, selectedSchools) => {
    const tempSchools = [...schools];
    for (const id of selectedSchools) {
      const school = {...tempSchools.find((e) => e.site_id === id)};
      if (!school[fileType]) {
        school[fileType] = {};
      }
      school[fileType].date = 'Generating...';
      school[fileType].username = 'Generating...';
      school[fileType].downloading = true;
    }
    setSchools(tempSchools);
    let exportUrl =
      TRACK_API_BASE_URL + `/esoy/school_setup_export/${fileType}?`;
    for (const id of selectedSchools) {
      exportUrl += `school_id=${id}&`;
    }

    // Inspired by this StackOverflow answer on how to download files:
    // https://stackoverflow.com/a/53230807

    axios({
      url: exportUrl,
      method: 'GET',
      responseType: 'blob', // important
      headers: {
        Authorization: Auth.getToken(),
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const schoolNames = selectedSchools.map((id) => {
          const school = schools.find((e) => e.site_id === id);
          return school.abbreviation;
        });

        // Merge school Names into string.
        const schoolNameString = schoolNames.join('_');

        const fileName = `${fileType}_import_file_${schoolNameString}.txt`;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        const tempSchools = [...schools];
        for (const id of selectedSchools) {
          const school = {...tempSchools.find((e) => e.site_id === id)};
          if (!school[fileType]) {
            school[fileType] = {};
          } else {
            school[fileType].downloading = false;
          }
        }
        setSchools(tempSchools);
        refetch();
      });
  };

  return (
    <div>
      <Table
        key={filteredSchools}
        dataSource={filterSchools(schools)}
        loading={schools.length === 0}
        columns={getColumnsForTable()}
        size="small"
        rowKey="site_id"
        bordered
        pagination={false}
        scroll={{x: '100%'}}
        rowSelection={{
          onChange: (selectedRowKeys) => {
            onChangeSchoolSelection(selectedRowKeys);
          },
        }}
      />
      <div className="flex-column">
        <div style={{marginTop: '10px'}}>
          <Switch
            onChange={(e) => onToggleJointImport(e)}
            checked={jointImport}
          />
          <span style={{marginLeft: '10px'}}>
            Import all selected schools in one file
          </span>
        </div>
        {files.map((file) => {
          return (
            <Button
              type="primary"
              onClick={() => setFilesToGenerate(file)}
              style={{width: 350, marginBottom: '10px'}}
              disabled={getSelectedSchools().length === 0}
              key={file}
            >
              Generate {file}
            </Button>
          );
        })}
        <Button
          type="primary"
          key="all"
          onClick={() => massGenerateFiles()}
          style={{width: 350}}
        >
          Mass generate all import files
        </Button>
      </div>
    </div>
  );
}

export default EsoyImportFiles;
