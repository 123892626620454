import {emptySplitApi} from 'api/apiSlice';
import {handleRTKQError} from 'utils/errorHandler';

const extractCategories = (response) => {
  const categoriesWithDuplicates = response.map((e) => e.folder) || [];
  const categories = [...new Set(categoriesWithDuplicates)]
    .filter((e) => !!e)
    .sort((a, b) => a.localeCompare(b));
  categories.push('Assorted');
  const reportsByCategory = categories.reduce((acc, category) => {
    acc[category] = [];
    return acc;
  }, {});
  for (const report of response) {
    if (!report.folder) {
      reportsByCategory['Assorted'].push(report);
    } else {
      reportsByCategory[report.folder].push(report);
    }
  }
  for (const category in reportsByCategory) {
    if (reportsByCategory[category].length) {
      reportsByCategory[category].sort((a, b) =>
        a.report_name.localeCompare(b.report_name)
      );
    } else {
      delete reportsByCategory[category];
    }
  }
  const publishedReports = {};
  Object.keys(reportsByCategory).forEach((f) => {
    const published = reportsByCategory[f].filter((r) => r.published);
    if (published.length) {
      publishedReports[f] = published;
    }
  });

  const publishedReportsList = response
    .filter((r) => r.published)
    .map((r) => ({
      value: r.id,
      label: `${r.folder ?? ''}/${r.report_name}`,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return {
    categories,
    reportsByCategory,
    publishedReports,
    publishedReportsList,
  };
};

const reportsSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchReportMeta: builder.query({
      query: ({slug, version}) =>
        `/data_reports/metadata/${slug}?version=${version}`,
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, arg) => [
        {
          type: 'ReportMeta',
          id: `${arg.slug}`,
        },
      ],
    }),
    fetchReport: builder.query({
      query: ({slug, version, queryUrl}) =>
        `/data_reports/report/${slug}?version=${version}${queryUrl ? `&${queryUrl}` : ''}`,
      transformResponse: (response) => {
        return {
          error: response.errors,
          report: response.report,
          sql: response.sql_statement,
          total: response.row_count,
        };
      },
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, arg) => [
        {type: 'Report', id: `${arg.slug}`},
      ],
    }),
    fetchAggregateReportsList: builder.query({
      query: () => `/data_reports/reports`,
      transformResponse: (response) => {
        return extractCategories(response);
      },
      transformErrorResponse: handleRTKQError,
      providesTags: ['ReportsList'],
    }),
    fetchAggregateReportsTree: builder.query({
      query: (version) => `/data_reports/reports_tree?version=${version}`,
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, arg) => [{type: 'ReportsTree', id: arg}],
    }),
    fetchReportSources: builder.query({
      query: () => `/data_reports/sources`,
      transformResponse: (response) => {
        return response.sources?.sort();
      },
      transformErrorResponse: handleRTKQError,
      providesTags: ['ReportSources'],
    }),
    fetchReportSourceColumns: builder.query({
      query: (source) => `/data_reports/sources/${source}`,
      transformResponse: (response) => {
        return response.columns;
      },
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, arg) => [
        {type: 'ReportSourceColumns', id: arg},
      ],
    }),
    saveReport: builder.mutation({
      query: (report) => ({
        url: `/data_reports/editor/`,
        method: 'POST',
        body: report,
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: (result, error, arg) => {
        return [
          {type: 'ReportMeta', id: `${arg.slug}`},
          {type: 'Report', id: `${arg.slug}`},
          'ReportsTree',
        ];
      },
    }),
    publishReport: builder.mutation({
      query: (report) => ({
        url: `/data_reports/editor/${report.slug}/?version=${report.version}`,
        method: 'PUT',
        body: report,
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: (result, error, arg) => {
        return [
          {type: 'ReportMeta', id: `${arg.slug}`},
          {type: 'Report', id: `${arg.slug}`},
          'ReportsTree',
        ];
      },
    }),
    deleteReport: builder.mutation({
      query: ({slug, version}) => ({
        url: `/data_reports/editor/${slug}/?version=${version}`,
        method: 'DELETE',
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: (result, error, arg) => {
        return [
          {type: 'ReportMeta', id: `${arg.slug}`},
          {type: 'Report', id: `${arg.slug}`},
          'ReportsTree',
        ];
      },
    }),
  }),
});

export const {
  useFetchReportMetaQuery,
  useFetchReportQuery,
  useFetchAggregateReportsListQuery,
  useFetchAggregateReportsTreeQuery,
  useFetchReportSourcesQuery,
  useFetchReportSourceColumnsQuery,
  useSaveReportMutation,
  usePublishReportMutation,
  useDeleteReportMutation,
} = reportsSlice;
