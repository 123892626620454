import {Button, Comment, Popconfirm, Tag} from 'antd';
import moment from 'moment';
import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

const VersionItem = ({
  version,
  currentVersion,
  publishedVersion,
  handleDelete,
  handlePublish,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Comment
      style={{
        borderTop: '1px solid #f0f0f0',
      }}
      actions={[
        <Button
          type="link"
          size="small"
          key="edit"
          disabled={version.version === currentVersion}
          onClick={() =>
            navigate(
              `/${location.pathname.split('/')[1]}/${version.slug}/editor?version=${version.version}`
            )
          }
        >
          View
        </Button>,
        <Popconfirm
          title="Are you sure you want to delete this version?"
          onConfirm={handleDelete}
          okText="Yes"
          cancelText="No"
          disabled={version.version === publishedVersion}
        >
          <Button
            size="small"
            type="link"
            danger
            disabled={version.version === publishedVersion}
          >
            Delete
          </Button>
        </Popconfirm>,
        version.version === publishedVersion ? (
          <Popconfirm
            title="Are you sure you want to unpublish this version?"
            onConfirm={handlePublish}
            okText="Yes"
            cancelText="No"
          >
            <Button size="small" type="link">
              Unpublish
            </Button>
          </Popconfirm>
        ) : (
          <Popconfirm
            title="Are you sure you want to publish this version?"
            onConfirm={handlePublish}
            okText="Yes"
            cancelText="No"
          >
            <Button size="small" type="link">
              Publish this Version
            </Button>
          </Popconfirm>
        ),
      ]}
      key={version.version}
      author={version.author ?? version.updated_by ?? 'Unknown'}
      avatar={null}
      content={
        <div>
          <strong>{version.title}</strong>
          {version.version === currentVersion && (
            <Tag color="blue" style={{marginLeft: 10}}>
              Current
            </Tag>
          )}
          {version.version === publishedVersion && (
            <Tag color="green" style={{marginLeft: 10}}>
              Published
            </Tag>
          )}
        </div>
      }
      datetime={moment(version.created ?? version.updated_at).format(
        'MMMM Do YYYY, h:mm a'
      )}
    />
  );
};

export default VersionItem;
