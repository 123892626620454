import {useFetchReportMetaQuery, useFetchReportQuery} from 'api/reportsSlice';
import DataTable from 'components/genericComponents/DataTable';
import useThrottle from 'components/genericComponents/Throttle';
import React, {useEffect, useState} from 'react';

const DataTableDisplay = ({reportSlug, params, setParams}) => {
  const [queryUrl, setQueryUrl] = useState('');

  const {data: reportMeta, isLoading: loadingMeta} = useFetchReportMetaQuery(
    {
      slug: reportSlug,
      version: 'published',
    },
    {
      skip: !reportSlug,
    }
  );
  const {
    data: reportData,
    isLoading: loadingData,
    isFetching,
  } = useFetchReportQuery(
    {
      slug: reportSlug,
      version: 'published',
      queryUrl,
    },
    {
      skip: !reportSlug || reportSlug === 'new',
    }
  );

  const throttledRequest = useThrottle(setQueryUrl);

  useEffect(() => {
    const newQueryUrl = `${params}`;
    if (newQueryUrl !== queryUrl) {
      throttledRequest(newQueryUrl);
    }
  }, [params]);

  return (
    <>
      <h4>{reportMeta?.name}</h4>
      {reportData && (
        <DataTable
          params={params}
          setParams={setParams}
          data={reportData}
          columns={reportMeta?.columns?.filter(
            (column) => column.column_type !== 'FILTER'
          )}
          filters={reportMeta?.columns?.filter(
            (column) => column.column_type === 'FILTER'
          )}
          loading={loadingMeta || loadingData || isFetching}
          key={reportSlug + isFetching + loadingData}
          bordered
          size="small"
        />
      )}
    </>
  );
};

export default DataTableDisplay;
