import {Button, Tooltip} from 'antd';
import FilterFields from 'components/genericComponents/FilterFields';
import React, {useState} from 'react';

const FiltersDisplay = ({filters, handleFiltersChange, handleClearFilters}) => {
  const [visible, setVisible] = useState(false);

  const onFilterChange = (alias, value) => {
    handleFiltersChange(alias, value);
  };

  const onClearFilters = () => {
    handleClearFilters();
  };

  return (
    <div className="flex-row" style={{margin: '10px 0 20px'}}>
      {filters.map((filter) => (
        <div
          className="flex-column"
          style={{
            minWidth: 300,
            alignItems: 'stretch',
          }}
          key={filter.alias}
        >
          {filter.description ? (
            <Tooltip title={filter.description} placement="top">
              <strong>{filter.alias ?? filter.source_column}: </strong>
            </Tooltip>
          ) : (
            <strong>{filter.alias ?? filter.source_column}: </strong>
          )}
          <FilterFields
            key={JSON.stringify(filter)}
            filter={filter}
            setFilter={(value) => onFilterChange(filter.alias, value)}
            open={visible}
            setVisible={setVisible}
          />
        </div>
      ))}
      <Button
        type="primary"
        onClick={onClearFilters}
        style={{alignSelf: 'flex-end'}}
      >
        Clear Filters
      </Button>
    </div>
  );
};

export default FiltersDisplay;
