import {Input} from 'antd';
import React from 'react';

const {TextArea} = Input;

const InputField = ({
  disabled,
  icon,
  label = '',
  multiline,
  onChange,
  placeholder,
  required = false,
  size = 'large',
  style = {},
  type,
  value,
}) => {
  return (
    <div
      style={{
        justifyContent: 'left',
        position: 'relative',
        minWidth: 100,
        ...style,
      }}
    >
      {label && (
        <label
          style={{
            backgroundColor: style.backgroundColor ?? 'white',
            color: 'rgba(0, 0, 0, 0.35)',
            fontSize: 10,
            left: 6,
            padding: '0 5px',
            position: 'absolute',
            top: -8,
            zIndex: 2,
          }}
        >
          {label}
          {required && <span>*</span>}
        </label>
      )}
      {multiline ? (
        <TextArea
          allowClear
          disabled={disabled}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          style={{
            backgroundColor: style.backgroundColor ?? 'white',
          }}
        />
      ) : (
        <Input
          // allowClear
          disabled={disabled}
          onChange={onChange}
          placeholder={placeholder}
          size={size}
          suffix={icon}
          type={type ?? 'text'}
          value={value}
          style={{
            backgroundColor: style.backgroundColor ?? 'white',
          }}
        />
      )}
    </div>
  );
};

export default InputField;
