import Auth from 'Auth';
import axios from 'axios';
import {TRACK_API_BASE_URL} from 'utils/consts';
import {handleApiError} from 'utils/errorHandler';

export const axiosInstance = axios.create({
  baseURL: TRACK_API_BASE_URL,
  timeout: 50000,
});

axiosInstance.interceptors.request.use(
  function (request) {
    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

let token = Auth.getToken();
if (token) {
  axiosInstance.defaults.headers.common['Authorization'] = `${token}`;
}

export const retryFunc = (func, retries = 2, interval = 500) => {
  return func().catch((err) => {
    if (retries <= 0) {
      handleApiError(err);
      return Promise.reject(err);
    }

    return new Promise(function (resolve) {
      setTimeout(function () {
        resolve(retryFunc(func, retries - 1, interval));
      }, interval);
    });
  });
};

export const retryGet = (url) => {
  return retryFunc(() => axiosInstance.get(url));
};

export const retryPost = (url, data) => {
  return retryFunc(() => axiosInstance.post(url, data));
};

export const retryPut = (url, data) => {
  return retryFunc(() => axiosInstance.put(url, data));
};
