import {Alert, Button, Modal, Spin} from 'antd';
import {
  cleanupHomeroomAssignments,
  fetchSchoolHomeroomAssignments,
} from 'api/apiHandler';
import TaskComplete from 'components/TaskComplete';
import AssignHomeroomsForGrade from 'components/esoy/AssignHomeroomsForGrade';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {CROSS_GRADE, ROLLOVER_MODE} from 'utils/consts';
import {handleApiError} from 'utils/errorHandler';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0px;
`;

const AssignHomerooms = ({siteId, rolloverMode}) => {
  const [gradespan, setGradespan] = useState(null);
  const [gradeHomerooms, setGradeHomerooms] = useState(null);
  const [crossgradeHomerooms, setCrossgradeHomerooms] = useState(null);
  const [completed, setCompleted] = useState(false);
  const [showCleanupModal, setShowCleanupModal] = useState(false);
  const [allStudentsAssigned, setAllStudentsAssigned] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    refreshDataSource();
  }, []);

  const refreshDataSource = () => {
    // We'll get the gradespan from the server.
    fetchSchoolHomeroomAssignments(siteId, rolloverMode)
      .then((response) => {
        setupAssignments(response.data);
      })
      .catch((error) => {
        error.data ??= {};
        error.data.detail =
          error?.response?.data?.detail ?? 'Error fetching assignments';
        handleApiError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const setupAssignments = (assignmentsFromServer) => {
    let gradespan = [...new Set(assignmentsFromServer.map((e) => e.grade))];
    let gradeHomerooms = {};
    let crossgradeHomerooms = {}; // We now have an effective gradespan, we'll loop over it to create a grade homeroom
    // object for each grade.

    gradespan.forEach((grade) => {
      let newGradeHomerooms = {}; // Loop over the homerooms in this grade.

      let homeroomsInGrade = assignmentsFromServer.filter(
        (e) => e.grade === grade
      ); // Let's make sure unassigned is first in the order.
      // We assume the other ids return sorted from the server.

      function cmp(a, b) {
        if (a === b) {
          return 0;
        }

        if (a === 'unassigned') {
          return -1;
        }

        if (b === 'unassigned') {
          return 1;
        }

        return parseInt(a) - parseInt(b);
      }

      let homeroomIds = homeroomsInGrade.map((e) => e.homeroom_id);
      homeroomIds = homeroomIds.sort(cmp);
      homeroomsInGrade.forEach((homeroom) => {
        newGradeHomerooms[homeroom['homeroom_id']] = homeroom;
      });

      if (grade === CROSS_GRADE) {
        // We'll remove the 'unassigned' homeroom from the cross grade homerooms, since it doesn't apply.
        delete newGradeHomerooms['unassigned'];
        crossgradeHomerooms = newGradeHomerooms;
      } else {
        gradeHomerooms[grade] = {
          homeroomOrder: homeroomIds,
          homerooms: newGradeHomerooms,
        };
      }
    });
    gradespan = gradespan.filter((e) => e !== CROSS_GRADE);
    setGradespan(gradespan);
    setGradeHomerooms(gradeHomerooms);
    setCrossgradeHomerooms(crossgradeHomerooms);
  };

  const checkAllStudentsAssigned = () => {
    if (!gradeHomerooms) {
      setAllStudentsAssigned(true);
    } // Check the stored students.

    let assigned = true;

    for (const grade in gradeHomerooms) {
      if (gradeHomerooms.hasOwnProperty(grade)) {
        const gradeObj = gradeHomerooms[grade];

        if (gradeObj.homerooms?.unassigned?.students?.length !== 0) {
          assigned = false;
        }
      }
    }
    setAllStudentsAssigned(assigned);
  };

  const cleanupIllegalAssignments = () => {
    setLoading(true);
    setShowCleanupModal(false);
    cleanupHomeroomAssignments(siteId)
      .then((response) => {
        refreshDataSource();
      })
      .catch((error) => {
        error.data ??= {};
        (error.data.detail = 'Error cleaning up assignments'),
          handleApiError(error);
      });
  };

  return (
    <div
      style={{
        minHeight: '90vh',
      }}
    >
      <Alert
        message="Assign Homerooms"
        description={
          <div>
            <ol>
              <li>
                By default, all students will be put into “Unassigned Students”
              </li>
              <li>
                (Optional) Use the "Auto-Populate Homerooms" button to randomly
                distribute all students in that grade level to homerooms.
              </li>
              <li>
                Drag and drop student names to move them to a different homeroom
                section
              </li>
              <ol>
                <li>
                  Hold <strong>Control</strong> or <strong>Command</strong> to
                  select individual students.
                </li>
                <li>
                  Hold <strong>Shift</strong> to select a range of students.
                </li>
              </ol>
              <li>
                You can use the “Auto-Populate Homerooms” button at any time to
                randomly distribute students from the “Unassigned Students”
                bucket
              </li>
            </ol>
            {rolloverMode === 'pre' ? (
              <p>
                Pre-Shutdown: Students appear on the Homeroom Assignments page
                for their Next Year Grade and Next School Indicator (i.e. the
                work you do in the NYSS task)<br></br>
                Post-Shutdown: Students appear on the Homeroom Assignments page
                for their current school and current grade level in PowerSchool
              </p>
            ) : (
              <p>
                Pre-Shutdown: Students appear on the Homeroom Assignments page
                for their Next Year Grade and Next School Indicator (i.e. the
                work you do in the NYSS task)<br></br>
                Post-Shutdown: Students appear on the Homeroom Assignments page
                for their current school and current grade level in PowerSchool
              </p>
            )}
            <p>
              Click{' '}
              <a
                href="https://uncommonschoolssupport.zendesk.com/hc/en-us/articles/360061987511-Assign-Homerooms"
                target="_"
              >
                here
              </a>{' '}
              to view our kBase article with more details, screenshots, and
              examples.
            </p>
          </div>
        }
        type="success"
      />

      <Container>
        <h3>Assign Homerooms</h3>

        <TaskComplete
          siteId={siteId}
          taskName="assign_homerooms"
          setCompletionStatus={(status) => setCompleted(status)}
          isTaskCompletable={() => allStudentsAssigned}
          taskIncompleteableMessage={
            <>
              Can only mark task as complete when all students are assigned to
              homerooms.
            </>
          }
          taskCompleteableMessage={
            <>All students assigned, can mark task as complete.</>
          }
        />
      </Container>
      <Modal
        open={showCleanupModal}
        onOk={cleanupIllegalAssignments}
        onCancel={() => setShowCleanupModal(false)}
        okText="Yes"
        cancelText="No"
      >
        <p>
          This action is for testing purposes and will clear all assignments of
          unreviewed students in this school.
        </p>
        <span>Are you sure you want to continue?</span>
      </Modal>

      {ROLLOVER_MODE === 'showall' && rolloverMode === 'pre' && (
        <Button
          onClick={() => setShowCleanupModal(true)}
          type="secondary"
          style={{
            marginBottom: '20px',
          }}
        >
          Cleanup Assignments of Unreviewed Students
        </Button>
      )}
      <Spin spinning={loading} size="large">
        {gradespan &&
          gradespan.map((grade) => {
            return (
              <div key={grade}>
                <AssignHomeroomsForGrade
                  completed={completed}
                  gradeHomerooms={gradeHomerooms[grade].homerooms}
                  homeroomOrder={gradeHomerooms[grade].homeroomOrder}
                  crossgradeHomerooms={crossgradeHomerooms}
                  grade={grade}
                  siteId={siteId}
                  rolloverMode={rolloverMode}
                  refreshDataSource={refreshDataSource.bind(this)}
                  allStudentsAssigned={allStudentsAssigned}
                  handleCheckAllStudentsAssigned={checkAllStudentsAssigned}
                />
              </div>
            );
          })}
      </Spin>
    </div>
  );
};

export default AssignHomerooms;
