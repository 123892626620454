import Auth from 'Auth';
import {Drawer, Switch} from 'antd';
import {useFetchPageQuery, usePublishPageMutation} from 'api/cmsSlice';
import PageForm from 'components/dataPortal/PageForm';
import EditorButton from 'components/genericComponents/EditorButton';
import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

const PageDrawer = () => {
  const location = useLocation();
  const pageSlug = location.pathname.split('/')[2];
  const editorMode =
    location.pathname.endsWith('/editor') &&
    Auth.permissions.access_to_data_portal_editor;

  const [drawerVisible, setDrawerVisible] = useState(false);

  const {data, isLoading} = useFetchPageQuery(
    {
      slug: pageSlug,
      version: location.search.split('=')[1] || 'latest',
    },
    {skip: !pageSlug || pageSlug === 'new'}
  );

  const [publishPage] = usePublishPageMutation();

  useEffect(() => {
    if (!editorMode) {
      setDrawerVisible(false);
    }
  }, [editorMode]);

  return (
    <>
      {editorMode && (
        <Drawer
          closable={true}
          extra={
            <Switch
              loading={isLoading}
              disabled={isLoading}
              checked={data?.published}
              onChange={() =>
                publishPage({...data, published: !data?.published})
              }
              checkedChildren="Unpublish"
              unCheckedChildren="Publish"
              type={data?.published ? 'danger' : 'primary'}
            />
          }
          getContainer={false}
          onClose={() => setDrawerVisible(false)}
          open={drawerVisible}
          placement="left"
          style={{
            position: 'fixed',
            overflowY: 'auto',
            minHeight: 'calc(100% - 64px)',
            boxShadow: '0 9px 28px 8px rgba(0, 0, 0, 0.05)',
          }}
          title="Page Editor"
          width={600}
        >
          <PageForm />
        </Drawer>
      )}
      <EditorButton
        drawerVisible={drawerVisible}
        setDrawerVisible={setDrawerVisible}
        path={`/data_portal/${pageSlug}`}
        key={pageSlug + editorMode}
      />
    </>
  );
};

export default PageDrawer;
