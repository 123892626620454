import {PlusCircleOutlined} from '@ant-design/icons';
import Auth from 'Auth';
import {
  Alert,
  Button,
  Collapse,
  Divider,
  Drawer,
  Popconfirm,
  Spin,
  Switch,
  message,
} from 'antd';
import {
  useDeleteReportMutation,
  useFetchReportMetaQuery,
  useFetchReportSourceColumnsQuery,
  useFetchReportSourcesQuery,
  usePublishReportMutation,
  useSaveReportMutation,
} from 'api/reportsSlice';
import {
  useCreateOrUpdateCategoryMutation,
  useCreateOrUpdateTagMutation,
  useFetchCategoriesQuery,
  useFetchTagsQuery,
} from 'api/tagsSlice';
import ColumnForm from 'components/dataReports/ColumnForm';
import EditorButton from 'components/genericComponents/EditorButton';
import InputField from 'components/genericComponents/Input';
import SelectOptions from 'components/genericComponents/SelectOptions';
import VersionItem from 'components/genericComponents/VersionItem';
import React, {useEffect, useState} from 'react';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import {useLocation, useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {generateUniqueId} from 'utils/helpers';

const {Panel} = Collapse;

const ColumnList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
`;

const DataReportEditor = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const reportSlug = location.pathname.split('/')[2];
  const params = new URLSearchParams(location.search);
  const editorMode =
    (location.pathname.endsWith('/editor') ||
      location.pathname.startsWith('/data_report_editor')) &&
    Auth.permissions.access_to_data_reports_editor;

  const [sourceReportId, setSourceReportId] = useState();
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [localReport, setLocalReport] = useState({
    columns: [],
  });
  const [drawerVisible, setDrawerVisible] = useState(false);

  const {data: reportData, isLoading} = useFetchReportMetaQuery(
    {
      slug: reportSlug,
      version: params.get('version') || 'latest',
    },
    {
      skip: !reportSlug || reportSlug === 'new',
    }
  );
  const {data: sources} = useFetchReportSourcesQuery();
  const {data: columns, isLoading: loadingColumns} =
    useFetchReportSourceColumnsQuery(sourceReportId, {
      skip: !sourceReportId,
    });
  const {data: categories} = useFetchCategoriesQuery('DATA_REPORTS');
  const {data: tags} = useFetchTagsQuery('DATA_REPORTS');

  const [saveReport] = useSaveReportMutation();
  const [publishReport] = usePublishReportMutation();
  const [deleteReport] = useDeleteReportMutation();
  const [createOrUpdateCategory] = useCreateOrUpdateCategoryMutation();
  const [createTag] = useCreateOrUpdateTagMutation();

  useEffect(() => {
    if (!editorMode) {
      setDrawerVisible(false);
    }
  }, [editorMode]);

  useEffect(() => {
    if (reportData?.source) {
      setSourceReportId(reportData.source);
    }
  }, [reportData?.source]);

  useEffect(() => {
    if (reportData) {
      setLocalReport({
        ...reportData,
      });
    }
  }, [reportData]);

  useEffect(() => {
    setSaveDisabled(JSON.stringify(reportData) === JSON.stringify(localReport));
  }, [reportData, localReport]);

  const handleColumnOrderChanged = (result) => {
    if (!result.destination) return;
    const temp = [...localReport?.columns];
    const [reorderedItem] = temp.splice(result.source.index, 1);
    temp.splice(result.destination.index, 0, reorderedItem);
    handleReportDataChanged(temp, 'columns');
  };

  const handleReportDataChanged = (value, type) => {
    const temp = {
      ...localReport,
      [type]: value ?? null,
    };
    if (type === 'source') {
      if (!value) {
        setSourceReportId(null);
      } else {
        if (!temp.report_name) {
          temp.report_name = value + ' Report';
        }
        setSourceReportId(value);
      }
    }
    setLocalReport(temp);
  };

  const handleAddColumn = (value) => {
    const newId = generateUniqueId(
      localReport?.columns.map((column) => column.id)
    );
    handleReportDataChanged(
      [
        ...localReport?.columns,
        {
          id: newId,
          column_type: value,
          filter_type: 'FREE_TEXT',
        },
      ],
      'columns'
    );
  };

  const handleColumnChanged = (value, column, field) => {
    const temp = [...localReport?.columns];

    const idx = temp?.findIndex((c) => c.id === column.id);
    temp[idx] = updateColumn(value, field, column);

    handleReportDataChanged(temp, 'columns');
  };

  const updateColumn = (value, field, column) => {
    const updatedColumn = {
      ...column,
      [field]: value,
    };

    if (updatedColumn.column_type === 'METRIC') {
      return {
        ...updatedColumn,
        ...updateMetric(field, updatedColumn),
      };
    } else if (field === 'source_column') {
      return {
        ...updatedColumn,
        ...updateColumnSelected(value, updatedColumn),
      };
    }
    return updatedColumn;
  };

  const updateMetric = (field, updatedMetric) => {
    if (field === 'metric_type' || field === 'source_column') {
      updatedMetric.alias = autogenerateMetricColumnName(updatedMetric);
    }
    return updatedMetric;
  };

  const updateColumnSelected = (value, changedElement) => {
    if (!value) return changedElement;

    return {
      ...changedElement,
      source_column: value,
      data_type: columns.find((column) => column.column_name === value)
        ?.data_type,
      alias: value,
    };
  };

  const autogenerateMetricColumnName = (metric) => {
    const c = metric.source_column?.toLowerCase().replace(' ', '_') ?? '';
    const m = metric.metric_type?.toLowerCase().replace(' ', '_') ?? '';
    return `${c}_${m}`;
  };

  const handleSave = () => {
    // if report hasn't changed, don't save
    if (JSON.stringify(reportData) === JSON.stringify(localReport)) {
      message.success({
        content: 'No changes to save',
        duration: 2,
        key: 'saveReport',
      });
      return;
    }
    saveReport(localReport)
      .unwrap()
      .then((response) => {
        message.success({
          content: 'Saved report',
          duration: 2,
          key: 'saveReport',
        });
        navigate(`/data_report/${response.slug}/editor`);
      })
      .catch((error) => {
        message.error({
          content: 'Error saving report',
          duration: 2,
          key: 'saveReport',
        });
      });
  };

  const handleDelete = (col) => {
    const temp = [...localReport?.columns];
    const idx = temp.findIndex((c) => c.id === col.id);
    temp.splice(idx, 1);
    handleReportDataChanged(temp, 'columns');
  };

  const discardChanges = () => {
    const metadata = reportData || {};
    setLocalReport(metadata);
  };

  const handleDeleteReport = (version) => {
    if (localReport?.published && localReport?.published_version === version) {
      message.error('Unpublish the report before deleting');
      return;
    }
    if (reportSlug) {
      deleteReport({
        slug: reportSlug,
        version,
      })
        .unwrap()
        .then(() => {
          message.success('Deleted report');
        });
    }
    if (!version) {
      navigate(`/data_report/editor`);
    } else {
      navigate(`/data_report/${reportSlug}/editor`);
    }
  };

  const handleAddColumnsFromSource = () => {
    const temp = columns.map((column) => ({
      column_type: 'DIMENSION',
      source_column: column.column_name,
      data_type: column.data_type,
      alias: column.column_name,
      filter_type: 'FREE_TEXT',
      id: generateUniqueId(localReport?.columns.map((c) => c.id)),
    }));
    handleReportDataChanged(temp, 'columns');
  };

  return (
    <>
      {editorMode && (
        <Drawer
          closable={true}
          extra={
            <Switch
              loading={isLoading}
              disabled={isLoading}
              checked={reportData?.published}
              onChange={() =>
                publishReport({
                  slug: reportSlug,
                  version: reportData?.version,
                  published: !reportData?.published,
                })
              }
              checkedChildren="Unpublish"
              unCheckedChildren="Publish"
              type={reportData?.published ? 'danger' : 'primary'}
            />
          }
          getContainer={false}
          onClose={() => setDrawerVisible(false)}
          open={drawerVisible}
          placement="left"
          style={{
            position: 'fixed',
            overflowY: 'auto',
            minHeight: 'calc(100% - 64px)',
            boxShadow: '0 9px 28px 8px rgba(0, 0, 0, 0.05)',
          }}
          title="Data Report Editor"
          width={600}
        >
          <Spin spinning={isLoading}>
            <div className="flex-column">
              {reportData?.errors?.length ? (
                <Alert
                  type="warning"
                  description={
                    <div>
                      {reportData.errors.map((error) => (
                        <div key={error}>* {error}</div>
                      ))}
                    </div>
                  }
                />
              ) : null}
              <div
                className="flex-row"
                style={{justifyContent: 'space-between'}}
              >
                <div style={{flexGrow: 1}}>
                  <SelectOptions
                    label="Report Source"
                    required={true}
                    options={sources}
                    selectedOption={localReport?.source}
                    changeSelection={(value) =>
                      handleReportDataChanged(value, 'source')
                    }
                    style={{width: '100%'}}
                  />
                </div>
                {localReport?.source &&
                  columns?.length &&
                  (!localReport?.columns?.length ||
                    localReport?.columns.filter((c) => c.errors).length ===
                      0) && (
                    <Button onClick={handleAddColumnsFromSource} size="large">
                      Load All Columns
                    </Button>
                  )}
              </div>
              <SelectOptions
                changeSelection={(value) =>
                  handleReportDataChanged(value, 'category')
                }
                label="Category"
                onAdd={(name) => {
                  createOrUpdateCategory({
                    name,
                    belongs_to: 'DATA_REPORTS',
                  })
                    .unwrap()
                    .then((res) => {
                      handleReportDataChanged(res, 'category');
                    });
                }}
                options={categories?.children}
                selectedOption={localReport?.category?.id}
                tree={true}
              />
              <SelectOptions
                changeSelection={(value) =>
                  handleReportDataChanged(
                    value.map((v) => tags?.find((c) => c.id === v) || null),
                    'tags'
                  )
                }
                label="Tags"
                onAdd={(name) => {
                  createTag({
                    name,
                    belongs_to: 'DATA_REPORTS',
                  })
                    .unwrap()
                    .then((res) => {
                      handleReportDataChanged(
                        [...(localReport?.tags || []), res],
                        'tags'
                      );
                    });
                }}
                options={tags?.map((c) => ({
                  value: c.id,
                  label: c.name,
                  color: c.color,
                }))}
                mode="tags"
                multiple
                selectedOption={localReport?.tags?.map((t) => t.id)}
                style={{width: '100%'}}
              />

              {/* name of new report */}
              <InputField
                label="Name"
                required={true}
                onChange={(e) =>
                  handleReportDataChanged(e.target.value, 'report_name')
                }
                value={localReport?.report_name}
                style={{width: '100%'}}
              />
              {/* description */}
              <InputField
                label="Description"
                onChange={(e) =>
                  handleReportDataChanged(e.target.value, 'short_description')
                }
                value={localReport?.short_description}
                multiline={true}
                style={{width: '100%'}}
              />
              <InputField
                label="Detailed Description"
                onChange={(e) =>
                  handleReportDataChanged(e.target.value, 'long_description')
                }
                value={localReport?.long_description}
                multiline={true}
                style={{width: '100%'}}
              />
            </div>
            <Divider />

            <Alert
              description={
                <ul>
                  <li>
                    Drag and drop columns to change their order in the report.
                  </li>
                  <li>
                    Frozen columns will always be displayed at the left of the
                    table.
                  </li>
                  <li>
                    The sort priority follows the order of columns in this list,
                    with the first column having the highest priority. Frozen
                    columns' sort priorities are also determined by their
                    position in this list, even though they’re displayed on the
                    left side of the table.
                  </li>
                  <li>
                    Filters defined in the <b>Filters</b> section below will be
                    external the table.
                  </li>
                </ul>
              }
              type="info"
              style={{marginBottom: '20px'}}
            />
            {
              <Spin spinning={loadingColumns}>
                {columns?.length > 0 && (
                  <>
                    <div className="flex-row">
                      {/* list of columns */}
                      <div
                        style={{
                          flex: '1 1 300px',
                        }}
                      >
                        <h4>Columns</h4>
                        <DragDropContext onDragEnd={handleColumnOrderChanged}>
                          <Droppable droppableId="1">
                            {(provided) => (
                              <ColumnList
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                              >
                                {localReport?.columns
                                  ?.filter((c) => c.column_type !== 'FILTER')
                                  .map((column, idx) => (
                                    <ColumnForm
                                      column={column}
                                      columns={columns}
                                      handleDelete={() => handleDelete(column)}
                                      idx={idx}
                                      key={column?.alias}
                                      setColumn={(value, field) =>
                                        handleColumnChanged(
                                          value,
                                          column,
                                          field
                                        )
                                      }
                                    />
                                  ))}
                                {provided.placeholder}
                              </ColumnList>
                            )}
                          </Droppable>
                        </DragDropContext>
                        <Button
                          onClick={() => handleAddColumn('DIMENSION')}
                          type="primary"
                        >
                          <PlusCircleOutlined />
                          Add Column
                        </Button>
                      </div>
                    </div>
                    <Divider />
                    <div>
                      <div
                        style={{
                          flex: '1 1 300px',
                        }}
                      >
                        {/* list of filters */}
                        <h4>Filters</h4>

                        <DragDropContext onDragEnd={handleColumnOrderChanged}>
                          <Droppable droppableId="2">
                            {(provided) => (
                              <ColumnList
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                              >
                                {localReport?.columns
                                  ?.filter((c) => c.column_type === 'FILTER')
                                  .map((filter, idx) => (
                                    <ColumnForm
                                      column={filter}
                                      columns={columns}
                                      handleDelete={() => handleDelete(filter)}
                                      idx={idx}
                                      key={filter?.alias}
                                      setColumn={(value, field) =>
                                        handleColumnChanged(
                                          value,
                                          filter,
                                          field
                                        )
                                      }
                                    />
                                  ))}
                                {provided.placeholder}
                              </ColumnList>
                            )}
                          </Droppable>
                        </DragDropContext>
                        <Button
                          onClick={() => handleAddColumn('FILTER')}
                          type="primary"
                        >
                          <PlusCircleOutlined />
                          Add Filter
                        </Button>
                      </div>
                    </div>
                  </>
                )}
              </Spin>
            }
            <Divider />
            <Button
              style={{margin: '10px 0'}}
              type="primary"
              onClick={handleSave}
              disabled={saveDisabled}
            >
              Save Report
            </Button>
            <Popconfirm
              title={
                <div>
                  Are you sure you want to discard changes to this report?
                  <br />
                  This cannot be undone.
                </div>
              }
              onConfirm={discardChanges}
              okText="Yes"
              cancelText="No"
              okButtonProps={{danger: true, type: 'default'}}
              cancelButtonProps={{type: 'primary'}}
              disabled={saveDisabled}
            >
              <Button style={{margin: '10px'}} disabled={saveDisabled}>
                Discard Changes
              </Button>
            </Popconfirm>
            <Popconfirm
              title={
                <div>
                  Are you sure you want to delete this report?
                  <br />
                  This cannot be undone.
                </div>
              }
              onConfirm={() => handleDeleteReport()}
              okText="Yes"
              cancelText="No"
              okButtonProps={{danger: true, type: 'default'}}
              cancelButtonProps={{type: 'primary'}}
              disabled={reportData?.published_version === reportData?.version}
            >
              <Button
                danger
                style={{margin: '10px 0'}}
                disabled={reportData?.published_version === reportData?.version}
              >
                Delete Report
              </Button>
            </Popconfirm>
            {localReport?.version_history?.length > 1 ? (
              <Collapse
                ghost
                style={{marginTop: '20px'}}
                expandIconPosition="end"
              >
                <Panel header="Version History" key="1">
                  {localReport?.version_history?.map((version) => {
                    return (
                      <VersionItem
                        currentVersion={localReport.version}
                        key={version.version}
                        publishedVersion={localReport.published_version}
                        version={version}
                        handleDelete={() => handleDeleteReport(version.version)}
                        handlePublish={() => {
                          publishReport({
                            slug: reportSlug,
                            version: version.version,
                            published: !version.published,
                          })
                            .unwrap()
                            .then(() => {
                              message.success({
                                content: version.published
                                  ? 'Unpublished successfully'
                                  : 'Published successfully',
                              });
                            });
                        }}
                      />
                    );
                  })}
                </Panel>
              </Collapse>
            ) : null}
          </Spin>
        </Drawer>
      )}
      <EditorButton
        drawerVisible={drawerVisible}
        setDrawerVisible={setDrawerVisible}
        path={`/data_report/${reportSlug}`}
        key={reportSlug + editorMode}
      />
    </>
  );
};

export default DataReportEditor;
