import {
  CaretRightOutlined,
  EditOutlined,
  EyeOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import Auth from 'Auth';
import {Button, Collapse, Divider, Spin, Tag, Tooltip} from 'antd';
import {useFetchSeedsQuery} from 'api/seedsSlice';
import {
  useCreateOrUpdateCategoryMutation,
  useCreateOrUpdateTagMutation,
  useDeleteCategoryMutation,
  useDeleteTagMutation,
  useFetchCategoriesQuery,
  useFetchTagsQuery,
} from 'api/tagsSlice';
import Input from 'components/genericComponents/Input';
import SelectOptions from 'components/genericComponents/SelectOptions';
import TagsAndCategories from 'components/genericComponents/TagsAndCategories';
import React, {useEffect, useMemo, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {setContrast} from 'utils/helpers';

const {Panel} = Collapse;

const SeedsOverviewPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const editorMode =
    location.pathname.endsWith('/editor') &&
    Auth.permissions.access_to_source_manager_editor;

  const {data: seeds, isLoading: isSeedsLoading} = useFetchSeedsQuery(
    editorMode ? 'latest' : 'published'
  );
  const {data: tags, isLoading: isTagsLoading} = useFetchTagsQuery('SEEDS');
  const {data: categories, isLoading: isCategoriesLoading} =
    useFetchCategoriesQuery('SEEDS');

  const [createOrUpdateCategory] = useCreateOrUpdateCategoryMutation();
  const [createOrUpdateTag] = useCreateOrUpdateTagMutation();
  const [deleteCategory] = useDeleteCategoryMutation();
  const [deleteTag] = useDeleteTagMutation();

  const [defaultData, setDefaultData] = useState([]);
  const [filterTags, setFilterTags] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [filterTagOptions, setFilterTagOptions] = useState([]);

  const additionalEditorTags = [
    {
      color: 'gold',
      id: 'draft',
      label: 'Draft',
      name: 'Draft',
      value: 'draft',
    },
    {
      color: 'blue',
      id: 'unpublished_schema_changes',
      label: 'Unpublished Schema Changes',
      name: 'Unpublished Schema Changes',
      value: 'unpublished_schema_changes',
    },
  ];

  const additionalTags = [
    {
      color: 'magenta',
      id: 'unpublished_data',
      label: 'Unpublished Data',
      name: 'Unpublished Data',
      value: 'unpublished_data',
    },
    ...(editorMode ? additionalEditorTags : []),
  ];

  useEffect(() => {
    if (!tags) return;
    const options = tags.map((tag) => ({
      ...tag,
      label: tag.name,
      value: tag.id,
    }));
    for (const tag of additionalTags) {
      if (!options.find((t) => t.label === tag.label)) {
        options.push(tag);
      }
    }
    setFilterTagOptions(options);
  }, [tags]);

  useEffect(() => {
    if (!filterTagOptions || !filterTagOptions.length) {
      return setFilterTags([]);
    }
    const params = new URLSearchParams(location.search);
    const tagParams = params.getAll('tag');
    const newTags =
      tagParams
        .map((t) => filterTagOptions.find((d) => d.name === t))
        .filter((t) => t) || [];
    setFilterTags(newTags);
  }, [location.search, filterTagOptions]);

  const loop = (data) =>
    data?.map((item) => ({
      ...item,
      name: item.title,
      isLeaf: true,
      title: (
        <>
          <Link
            to={`/source_manager/${item.title}/${editorMode ? 'editor' : ''}`}
            style={{fontSize: 16}}
          >
            {item.title}
          </Link>
          {additionalTags.map((tag) => {
            if (tag.value === 'draft' && item.published_version) {
              // if published_version exists, it is not a draft
              return null;
            }
            if (
              tag.value === 'unpublished_schema_changes' &&
              (!item.published_version ||
                item.published_version === item.version)
            ) {
              // if there are no versions later than the published version, there are no unpublished schema changes
              return null;
            }
            if (tag.value === 'unpublished_data' && !item.is_edited) {
              return null;
            }
            return (
              <Tag
                color={tag.color}
                style={{
                  marginRight: 3,
                  marginLeft: 5,
                  cursor: 'pointer',
                }}
                key={tag.name}
                onClick={() => onChangeFilterTags([tag.value])}
              >
                {tag.label}
              </Tag>
            );
          })}
          {item.tags?.map((d) => {
            const tag = tags?.find((t) => t.id === d);
            if (!tag) {
              return null;
            }
            const textColor = setContrast(tag.color);
            return (
              <Tag
                color={tag.color}
                key={tag.name}
                onClick={() => onChangeFilterTags([d])}
                style={{
                  marginRight: 1,
                  marginLeft: 5,
                  color: textColor,
                  cursor: 'pointer',
                  borderColor:
                    textColor === '#000000d9' ? '#d9d9d9' : tag.color,
                }}
              >
                {tag.name}
              </Tag>
            );
          })}
        </>
      ),
      key: item.type + item.id,
      children: loop(item.children),
    }));

  useEffect(() => {
    if (!seeds) return;
    const newData = loop(seeds.children);
    setDefaultData(newData);
  }, [seeds, tags, editorMode]);

  const renderCollapse = (data) =>
    data?.map((item) => {
      return item.type === 'node' ? (
        <Panel
          key={item.key}
          header={<h3>{item.name}</h3>}
          className="site-collapse-custom-panel"
        >
          <Collapse
            defaultActiveKey={item.children.map((c) => c.key)}
            ghost
            bordered={false}
            expandIcon={({isActive}) => (
              <CaretRightOutlined
                style={{paddingTop: 8}}
                rotate={isActive ? 90 : 0}
              />
            )}
          >
            {renderCollapse(item.children)}
          </Collapse>
        </Panel>
      ) : (
        <div className="site-collapse-custom-content" key={item.key}>
          {item.title}
        </div>
      );
    });

  const filteredDataList = useMemo(() => {
    // recursively filter defaultData
    // only return nodes that have children or match the search value
    // if a node name matches the search value, return the node and all its children
    const filterData = (data) => {
      const filtered = [];
      data.forEach((item) => {
        if (item.name.toLowerCase().includes(searchValue.toLowerCase())) {
          filtered.push(item);
        } else if (item.children) {
          const children = filterData(item.children);
          if (children.length) {
            filtered.push({
              ...item,
              children,
            });
          }
        }
      });
      return filtered;
    };
    const filteredData = searchValue ? filterData(defaultData) : defaultData;

    // recursively filter filteredData based on filterTags
    // only return nodes that have children
    // only return leaf nodes that have all the tags in filterTags
    // if the tag is 'draft' or 'unpublished_schema_changes', check if the page is a draft or has unpublished changes
    const filterTagsData = (data) => {
      const filtered = [];
      data.forEach((item) => {
        if (item.tags) {
          const hasTag = filterTags.every((t) => {
            if (t.id === 'draft') {
              return !item.published_version;
            }
            if (t.id === 'unpublished_schema_changes') {
              return (
                item.published_version &&
                item.published_version !== item.version
              );
            }
            if (t.id === 'unpublished_data') {
              return item.is_edited;
            }
            return item.tags.findIndex((s) => s === t.id) !== -1;
          });
          if (hasTag) {
            filtered.push(item);
          }
        } else if (item.children) {
          const children = filterTagsData(item.children);
          if (children.length) {
            filtered.push({
              ...item,
              children,
            });
          }
        }
      });
      return filtered;
    };
    const filteredTagsData = filterTags
      ? filterTagsData(filteredData)
      : filteredData;

    return filteredTagsData;
  }, [defaultData, searchValue, location.search, filterTags]);

  const onChangeFilterTags = (value) => {
    const search = new URLSearchParams(location.search);
    // delete all tag params
    search.delete('tag');
    value.forEach((t) => {
      const tag = filterTagOptions?.find((d) => d.id === t);
      if (!tag) {
        return;
      }
      search.append('tag', tag?.name);
    });
    navigate(`${location.pathname}?${search.toString()}`);
  };

  return (
    <div>
      <h1>Track-Managed Sources</h1>
      <div className="flex-row" style={{margin: '20px'}}>
        <Input
          allowClear
          label="Search"
          onChange={(e) => setSearchValue(e.target.value)}
          prefix={<SearchOutlined />}
          style={{width: '450px'}}
          value={searchValue}
        />
        <SelectOptions
          allowClear={true}
          changeSelection={onChangeFilterTags}
          label="Filter by Tag"
          mode="tags"
          multiple={true}
          options={filterTagOptions}
          required={false}
          selectedOption={filterTags.map((t) => t.id)}
          style={{width: '450px'}}
        />
      </div>
      <Spin spinning={isSeedsLoading || isTagsLoading || isCategoriesLoading}>
        {filteredDataList.length ? (
          <Collapse
            defaultActiveKey={filteredDataList.map((d) => d.key)}
            ghost
            bordered={false}
            expandIcon={({isActive}) => (
              <CaretRightOutlined
                style={{paddingTop: 8}}
                rotate={isActive ? 90 : 0}
              />
            )}
          >
            {renderCollapse(filteredDataList)}
          </Collapse>
        ) : (
          <div>There are no saved sources yet.</div>
        )}
      </Spin>
      {editorMode && (
        <div>
          <Divider />
          <TagsAndCategories
            categories={categories}
            createOrUpdateCategory={(data) =>
              createOrUpdateCategory({
                ...data,
                belongs_to: 'SEEDS',
              })
            }
            createOrUpdateTag={(data) =>
              createOrUpdateTag({
                ...data,
                belongs_to: 'SEEDS',
              })
            }
            deleteCategory={(category_id) =>
              deleteCategory({
                category_id,
                belongs_to: 'SEEDS',
              })
            }
            deleteTag={(tag_id) =>
              deleteTag({
                tag_id,
                belongs_to: 'SEEDS',
              })
            }
            tags={tags}
          />
        </div>
      )}
      {Auth.permissions.access_to_source_manager_editor ? (
        <Tooltip
          title={editorMode ? 'Preview mode' : 'Editor mode'}
          placement="right"
        >
          <Button
            onClick={() =>
              navigate(`/source_manager${editorMode ? '' : '/editor'}`)
            }
            type="primary"
            style={{
              top: '15%',
              left: 0,
              height: '50px',
              position: 'fixed',
              zIndex: 1001,
              fontSize: '1.8em',
            }}
          >
            {editorMode ? <EyeOutlined /> : <EditOutlined />}
          </Button>
        </Tooltip>
      ) : null}
    </div>
  );
};

export default SeedsOverviewPage;
