import {Alert} from 'antd';
import {
  useFetchSchoolGradespanQuery,
  useFetchTeacherAssignmentsQuery,
} from 'api/esoySlice';
import TaskComplete from 'components/TaskComplete';
import TeacherAssignTable from 'components/esoy/TeacherAssignTable';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {getDisplayGrade} from 'utils/helpers';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0px;
`;

const AssignTeachers = ({siteId}) => {
  const {data} = useFetchTeacherAssignmentsQuery(siteId, {
    skip: !siteId,
  });
  const {data: schoolGradespanData, isLoading: gradespanLoading} =
    useFetchSchoolGradespanQuery(siteId, {
      skip: !siteId,
    });

  const [completed, setCompleted] = useState(false);
  const [allTeachersAssigned, setAllTeachersAssigned] = useState(false);

  useEffect(() => {
    if (data) {
      checkAllAssigned();
    }
  }, [data]);

  const checkAllAssigned = () => {
    if (!schoolGradespanData?.gradespan) {
      setAllTeachersAssigned(false);
    } // Check the stored assignments.

    let assigned = true;

    for (const grade in data?.assignments) {
      if (data?.assignments.hasOwnProperty(grade)) {
        // This could be the teachers array.
        if (grade === 'teachers') continue;
        const gradeObj = data?.assignments[grade];
        let homeroomIds = gradeObj.homerooms.map((e) => Object.keys(e)[0]);
        let courseIds = gradeObj.courses.map((e) => Object.keys(e)[0]); // Loop for every course and homeroom.

        for (let i = 0; i < courseIds.length; i++) {
          const courseId = courseIds[i];
          for (let j = 0; j < homeroomIds.length; j++) {
            const homeroomId = homeroomIds[j];
            let assignment = gradeObj.assignments.find(
              (e) =>
                e.course_number === courseId &&
                e.homeroom_id.toString() === homeroomId
            );
            if (!assignment) {
              assigned = false;
            }
          }
        }
      }
    }
    setAllTeachersAssigned(assigned);
  };

  return (
    <div>
      <Alert
        message="Assign Teachers"
        description={
          <div>
            <p>
              For each section of each course, choose the lead teacher from the
              dropdown.
            </p>
            <p>For new teachers that are not yet in the dropdown:</p>
            <ol>
              <li>
                The DOO should follow the steps in the Hiring process to ensure
                that your new hire’s information has been sent from
                SmartRecruiters to Workday.
              </li>
              <li>
                As IT Support processes accounts for new hires, they will be
                added to PowerSchool and will appear in TRACK the following day.
              </li>
            </ol>
            <p>
              If you have a position that has not yet been filled, choose the
              DOO for now. This can be updated directly in PowerSchool once the
              role is filled.
            </p>

            <p>
              <strong>Notes:&#32;</strong>
            </p>
            <ul>
              <li>
                This process is only for assigning lead teachers. Ops teams will
                be able to add co-teachers directly in PowerSchool after this
                task is processed.
              </li>
              <li>
                Lead teachers will receive an email link to their Google
                Classroom ~10 days prior to first day of school
              </li>
            </ul>

            <p>
              Click{' '}
              <a
                href="https://uncommonschoolssupport.zendesk.com/hc/en-us/articles/4404692428813"
                target="_"
              >
                here
              </a>{' '}
              to view our kBase article with more details, screenshots, and
              examples.
            </p>
          </div>
        }
        type="success"
      />

      <Container>
        <h3>Assign Teachers</h3>

        <TaskComplete
          siteId={siteId}
          taskName="assign_teachers"
          setCompletionStatus={(status) => setCompleted(status)}
          isTaskCompletable={() => allTeachersAssigned}
          taskIncompleteableMessage={
            <>
              Can only mark task as complete when all courses are assigned a
              teacher.
            </>
          }
          taskCompleteableMessage={
            <>All courses assigned, can mark task as complete.</>
          }
        />
      </Container>

      {schoolGradespanData?.gradespan?.length ? (
        schoolGradespanData?.gradespan.map((grade) => {
          return (
            <div
              key={grade}
              style={{
                marginBottom: '30px',
              }}
            >
              <h3>{getDisplayGrade(grade)}</h3>
              <TeacherAssignTable
                disabled={completed}
                grade={grade}
                siteId={siteId}
                handleCheckAllAssigned={checkAllAssigned}
              />
            </div>
          );
        })
      ) : (
        <p>No grades found.</p>
      )}
    </div>
  );
};

export default AssignTeachers;
