import {LoadingOutlined} from '@ant-design/icons';
import {Button} from 'antd';
import {useSetAuditCommentMutation} from 'api/auditsSlice';
import InputField from 'components/genericComponents/Input';
import React, {useState} from 'react';
import {extractHTMLTextContent} from 'utils/helpers';

const FieldComment = ({comment, auditId}) => {
  const [changed, setChanged] = useState(false);
  const [text, setText] = useState(extractHTMLTextContent(comment));

  const [setAuditComment, {isLoading}] = useSetAuditCommentMutation();

  const handleSave = (e) => {
    setAuditComment({auditId, comment: text}).then(() => {
      setChanged(false);
    });
  };

  const handleCancel = (e) => {
    setChanged(false);
    setText(comment);
  };

  return (
    <div>
      <InputField
        value={text}
        style={{width: '90%'}}
        multiline={true}
        onChange={(e) => {
          setText(e.target.value);
          setChanged(true);
        }}
        placeholder="Add comment on this field audit..."
      />

      <div className="flex-row" style={{margin: '10px'}}>
        <Button
          type="primary"
          key="saveComment"
          onClick={handleSave}
          disabled={!changed}
        >
          {isLoading ? <LoadingOutlined /> : 'Save changes'}
        </Button>
        <Button danger key="cancel" onClick={handleCancel} disabled={!changed}>
          Revert
        </Button>
      </div>
    </div>
  );
};

export default FieldComment;
